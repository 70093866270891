import React from "react";
import PropTypes from "prop-types";
import { Button } from "components/ui/button";
import { ReactComponent as CloseIcon } from "icons/close.svg";
import { ReactComponent as ArrowRightIcon } from "icons/arrow/right.svg";
import { ReactComponent as ArrowLeftIcon } from "icons/arrow/left.svg";
import { ReactComponent as CheckmarkIcon } from "icons/status-icons/complete.svg";
const TutorialPopup = ({
  title,
  text,
  elementId,
  stepId,
  currentStep,
  maxSteps,
  onNextStep,
  onPreviousStep,
  trianglePosition, // New prop for triangle position
}) => {
  const [show, setShow] = React.useState(false);

  let popUpWidth = 400;

  const [position, setPosition] = React.useState({
    top: 0,
    left: 0,
    popUpWidth: popUpWidth,
    innerDivClasses: "",
  });

  // React.useEffect(() => {
  //   const targetElement = document.getElementById(elementId);

  //   if (targetElement) {
  //     const { top, left } = targetElement.getBoundingClientRect();
  //     setPosition({ top, left });
  //     setShow(stepId === currentStep);
  //   }
  // }, [elementId, stepId, currentStep]);
  const popUpHeight = 200;
  React.useEffect(() => {
    const targetElement = document.getElementById(elementId);

    if (targetElement) {
      const { top, left, width, height } =
        targetElement.getBoundingClientRect();
      // const { top, left, width, height } = targetElement.getBoundingClientRect();
      let tooltipTop = top;
      let tooltipLeft = left;
      let innerDivClasses = "";
      // let tooltipRight = "auto";
      let popUpWidth = 400;

      switch (trianglePosition) {
        // case "left-top":
        case "left-top":
          tooltipTop = top + height * 0.5 - 16;
          tooltipLeft = left + 24;
          innerDivClasses = "mb-auto";
          break;
        case "left-middle":
          tooltipTop = top + height * 0.5 - popUpHeight / 2;
          tooltipLeft = left + 24;
          break;
        case "left-bottom":
          tooltipTop = top + (height * 1) - popUpHeight - 16;
          tooltipLeft = left + 24;
          innerDivClasses = "mt-auto";
          break;

        case "top-left":
          tooltipTop = top + height + 16;
          tooltipLeft = left + width / 2 - 8;
          innerDivClasses = "mb-auto";
          break;
        case "top-center":
          tooltipTop = top + height + 16;
          tooltipLeft = left - popUpWidth / 2 + width / 2;
          innerDivClasses = "mb-auto";
          break;
        case "top-right":
          tooltipTop = top + height + 16;
          tooltipLeft = left - popUpWidth + width / 2 + 16;
          innerDivClasses = "mb-auto";
          break;

        case "right-top":
          tooltipTop = top + height * 0.5 - 16;
          tooltipLeft = left - popUpWidth - width - 24;
          innerDivClasses = "mb-auto";
          break;
        case "right-middle":
          tooltipTop = top + height * 0.5 - popUpHeight / 2;
          tooltipLeft = left - popUpWidth - width - 24;
          break;
        case "right-bottom":
          tooltipTop = top + height * 1 - popUpHeight - 16;
          tooltipLeft = left - popUpWidth - width - 24;
          innerDivClasses = "mt-auto";
          break;

        case "bottom-left":
          tooltipTop = top - popUpHeight - 16;
          tooltipLeft = left - width / 2 - 8;
          innerDivClasses = "mt-auto";
          break;
        case "bottom-center":
          tooltipTop = top - popUpHeight - 16;
          tooltipLeft = left - popUpWidth * 0.5 + width / 2;
          innerDivClasses = "mt-auto";
          break;

        case "bottom-right":
          tooltipTop = top - popUpHeight - 16;
          tooltipLeft = left - popUpWidth + width / 2 + 8;
          innerDivClasses = "mt-auto";

          break;
        default:
          break;
      }

      // Adjust to prevent tooltip from going off-screen
      const viewportWidth = window.innerWidth;
      if (tooltipLeft < 0) {
        // Prevent overflow on the left
        tooltipLeft = 8; // 20px margin for safety
      }
      if (tooltipLeft + popUpWidth > viewportWidth) {
        // Prevent overflow on the right
          popUpWidth = viewportWidth - Math.abs(tooltipLeft) - 8;
      }
      
    

      setPosition({
        top: tooltipTop,
        left: tooltipLeft,
        popUpWidth: popUpWidth,
        innerDivClasses: innerDivClasses,
      });
      setShow(stepId === currentStep);
    }
  }, [elementId, stepId, currentStep, trianglePosition, popUpWidth]);

  const hidePopup = () => setShow(false);

  if (!show) {
    return null;
  }

  const getTriangleStyle = () => {
    let triangleStyle = {};

    switch (trianglePosition) {
      case "left-top":
        triangleStyle = {
          borderTop: "8px solid transparent",
          borderRight: "8px solid currentColor",
          borderBottom: "8px solid transparent",
          position: "absolute",
          top: "8px",
          left: "-8px",
        };
        break;
      case "left-middle":
        triangleStyle = {
          borderTop: "8px solid transparent",
          borderRight: "8px solid currentColor",
          borderBottom: "8px solid transparent",
          position: "absolute",
          top: "calc(50% - 8px)",
          left: "-8px",
        };
        break;
      case "left-bottom":
        triangleStyle = {
          borderTop: "8px solid transparent",
          borderRight: "8px solid currentColor",
          borderBottom: "8px solid transparent",
          position: "absolute",
          bottom: "8px",
          left: "-8px",
        };
        break;
      case "top-left":
        triangleStyle = {
          borderTop: "8px solid transparent",
          borderLeft: "8px solid transparent",
          borderRight: "8px solid transparent",
          borderBottom: "8px solid currentColor",
          position: "absolute",
          top: "-16px",
          left: "8px",
        };
        break;
      case "top-center":
        triangleStyle = {
          borderTop: "8px solid transparent",
          borderLeft: "8px solid transparent",
          borderRight: "8px solid transparent",
          borderBottom: "8px solid currentColor",
          position: "absolute",
          top: "-16px",
          left: "calc(50% - 8px)",
        };
        break;
      case "top-right":
        triangleStyle = {
          borderTop: "8px solid transparent",
          borderLeft: "8px solid transparent",
          borderRight: "8px solid transparent",
          borderBottom: "8px solid currentColor",
          position: "absolute",
          top: "-16px",
          right: "8px",
        };
        break;
      case "right-top":
        triangleStyle = {
          borderTop: "8px solid transparent",
          borderLeft: "8px solid currentColor",
          borderRight: "8px solid transparent",
          borderBottom: "8px solid transparent",
          position: "absolute",
          top: "8px",
          right: "-16px",
        };
        break;
      case "right-middle":
        triangleStyle = {
          borderTop: "8px solid transparent",
          borderLeft: "8px solid currentColor",
          borderRight: "8px solid transparent",
          borderBottom: "8px solid transparent",
          position: "absolute",
          top: "calc(50% - 8px)",
          right: "-16px",
        };
        break;
      case "right-bottom":
        triangleStyle = {
          borderTop: "8px solid transparent",
          borderLeft: "8px solid currentColor",
          borderRight: "8px solid transparent",
          borderBottom: "8px solid transparent",
          position: "absolute",
          bottom: "8px",
          right: "-16px",
        };
        break;
      case "bottom-left":
        triangleStyle = {
          borderTop: "8px solid currentColor",
          borderLeft: "8px solid transparent",
          borderRight: "8px solid transparent",
          borderBottom: "8px solid transparent",
          position: "absolute",
          bottom: "-16px",
          left: "8px",
        };
        break;
      case "bottom-center":
        triangleStyle = {
          borderTop: "8px solid currentColor",
          borderLeft: "8px solid transparent",
          borderRight: "8px solid transparent",
          borderBottom: "8px solid transparent",
          position: "absolute",
          bottom: "-16px",
          left: "calc(50% - 8px)",
        };
        break;
      case "bottom-right":
        triangleStyle = {
          borderTop: "8px solid currentColor",
          borderLeft: "8px solid transparent",
          borderRight: "8px solid transparent",
          borderBottom: "8px solid transparent",
          position: "absolute",
          bottom: "-16px",
          right: "8px",
        };
        break;
      default:
        break;
    }

    return triangleStyle;
  };

  return (
    <div
      className=" flex items-center"
      style={{
        position: "fixed",
        top: position.top,
        left: position.left,
        height: popUpHeight,
        maxWidth: position.popUpWidth,
        // maxWidth: popUpWidth,
        zIndex: 10000,
      }}>
      <div className={`relative w-full ${position.innerDivClasses}`}>
        <div className="bg-gray-900 dark:bg-white flex flex-col gap-y-2 p-4 shadow-lg rounded-md">
          <div
            className="text-gray-900 dark:text-white"
            style={getTriangleStyle()}></div>
          <div className="flex flex-row justify-between">
            <h4 className="text-xl text-white dark:text-gray-900 font-bold mb-0">
              {title}
            </h4>
            <Button variant="outline" className="p-1 h-fit dark:text-white" onClick={hidePopup}>
              <CloseIcon className="w-5 h-5" />
            </Button>
          </div>
          <p className="text-sm text-gray-200 dark:text-gray-700">{text}</p>
          <div className="flex flex-row gap-x-4 items-center justify-between">
            <Button
            disabled={currentStep === 1}
              variant="outline"
              className="px-2 py-1 flex flex-row gap-x-1 dark:text-white dark:disabled:text-gray-300"
              onClick={onPreviousStep}>
              <ArrowLeftIcon className="w-5 h-5" />
              <span className="hidden sm:inline-block">

              Paso Anterior
              </span>
            </Button>
            <p className="text-sm text-gray-200 dark:text-gray-700">
              Paso {currentStep} / {maxSteps}
            </p>
            {currentStep === maxSteps ? (
              <Button
                variant="outline"
                className="px-2 bg-green-500 border-green-400 hover:bg-green-400 text-white  font-semibold py-1 flex flex-row gap-x-1"
                onClick={hidePopup}>
                <span className="hidden sm:inline-block">

Listo
</span>
                <CheckmarkIcon className="w-5 h-5 p-0.5" />
              </Button>
            ) : (
              <Button
                variant="outline"
                className="px-2 py-1 flex flex-row gap-x-1 dark:text-white"
                onClick={onNextStep}>
                                <span className="hidden sm:inline-block">

                Proximo Paso
</span>
                <ArrowRightIcon className="w-5 h-5" />
              </Button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

TutorialPopup.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  elementId: PropTypes.string.isRequired,
  stepId: PropTypes.number.isRequired,
  currentStep: PropTypes.number.isRequired,
  maxSteps: PropTypes.number.isRequired,
  onNextStep: PropTypes.func.isRequired,
  onPreviousStep: PropTypes.func.isRequired,
  trianglePosition: PropTypes.oneOf([
    "left-top",
    "left-middle",
    "left-bottom",
    "top-left",
    "top-center",
    "top-right",
    "right-top",
    "right-middle",
    "right-bottom",
    "bottom-left",
    "bottom-center",
    "bottom-right",
  ]),
};

export default TutorialPopup;

