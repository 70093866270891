import { useState, useEffect } from 'react';

const useWidgetPermissions = (widgetType, isLoadingUserPermissions, setIsLoadingUserPermissions) => {
  const [permissions, setPermissions] = useState({ canView: false, canEdit: false });
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const fetchPermissions = async () => {
      let permissionsString = sessionStorage.getItem('frontendPermissions');
      while (!permissionsString) {
        if (isLoadingUserPermissions) {
          console.log('Waiting for permissions to load...');
          await new Promise(resolve => setTimeout(resolve, 1000)); // Wait for 1 second before checking again
          permissionsString = sessionStorage.getItem('frontendPermissions');
        } else {
          setIsLoadingUserPermissions(true);
          await new Promise(resolve => setTimeout(resolve, 1000));
          permissionsString = sessionStorage.getItem('frontendPermissions');
        }
      }
      setIsLoadingUserPermissions(false);

      try {
        const permissions = JSON.parse(permissionsString);

        if (!Array.isArray(permissions)) {
          throw new Error('Permissions format is incorrect');
        }

        const canView = permissions.includes(`${widgetType}.view`);
        const canEdit = permissions.includes(`${widgetType}.edit`);
        setPermissions({ canView, canEdit });
      } catch (error) {
        console.log('Error in useWidgetPermissions:', error);
        setPermissions({ canView: false, canEdit: false });
      } finally {
        setLoading(false);
      }
    };

    fetchPermissions();
  }, [widgetType, isLoadingUserPermissions, setIsLoadingUserPermissions]);
  // console.log('permissions:', widgetType, permissions, loading, isLoadingUserPermissions);
  return { permissions, loading };
};

export default useWidgetPermissions;
