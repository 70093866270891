import React, { useState } from "react";
import { Button } from "components/ui/button";
import { ButtonGroup } from "@mui/material";
import { Slider } from "components/ui/slider";
import { useStateContext } from "contexts/ContextProvider";

const SetupApperance = ({ userProfile, updateUserProfile }) => {
  const [settings, setSettings] = useState({
    whoCanContact: userProfile.userSettings.whoCanContact || "everyone",
    theme: userProfile.userSettings.darkMode || "system",
    fontSize: userProfile.userSettings.fontSize || "16px",
  });

  const fontSizeForSlider = parseInt(
    (settings.fontSize || "16px").replace("px", "")
  );
  const { setUserProfile } = useStateContext(); // State for user profile

  const handleOptionChange = (value) => {
    setSettings({ ...settings, theme: value });
    // Update user profile state
    setUserProfile((prevProfile) => ({
      ...prevProfile,
      userSettings: {
        ...prevProfile.userSettings,
        darkMode: value,
      },
    }));
  };

  const themeOptions = [
    { label: "Claro", value: "light" },
    { label: "Oscuro", value: "dark" },
    { label: "Automático", value: "system" },
  ];

  const handleChange = (e) => {
    const { name, value, checked, type } = e.target;
    let newValue =
      type === "checkbox"
        ? checked
        : name === "fontSize"
        ? `${value}px`
        : value;

    setSettings((prevSettings) => ({
      ...prevSettings,
      [name]: newValue,
    }));

    if (name === "fontSize") {
      // Update the global user profile
      updateUserProfile((prevProfile) => ({
        ...prevProfile,
        userSettings: {
          ...prevProfile.userSettings,
          fontSize: newValue, // Ensure the value is stored with 'px'
        },
      }));
    }
  };

  return (
    <div className="max-w-md md:max-w-2xl mx-auto w-full flex flex-col gap-y-4 md:gap-y-8">
      <div className="md:text-center">
        <h2 className="font-medium leading-7 dark:text-white">Apariencia</h2>
        <span className="text-sm dark:text-gray-200">
          Configura la apariencia de la interfaz de usuario.
        </span>
      </div>

      <div className="flex justify-center items-center w-full flex-col gap-2 md:gap-4">
        <h4 className="dark:text-white" >Tema</h4>
        <ButtonGroup className="flex justify-center">
          {themeOptions.map((option, index) => (
            <Button
              key={option.value}
              className={`${
                index === 0
                  ? "rounded-r-none border border-r"
                  : index === themeOptions.length - 1
                  ? "rounded-l-none border border-l-0 "
                  : "rounded-none border border-l-0 border-r"
              } ${settings.theme === option.value ? "dark:text-black" : "dark:text-white"} `}
              variant={settings.theme === option.value ? "default" : "outline"}
              onClick={() => handleOptionChange(option.value)}>
              {option.label}
            </Button>
          ))}
        </ButtonGroup>

        <h4 className="dark:text-white" >Tamaño de fuente</h4>
        <div className="relative w-full max-w-72">
          <Slider
            name="fontSize"
            value={[fontSizeForSlider]}
            onValueChange={(value) =>
              handleChange({ target: { name: "fontSize", value: value[0] } })
            } // Handle value changes
            min={12}
            max={20}
            step={2}
            values={[
              { value: 12, label: "Muy pequeño" },
              { value: 14, label: "Pequeño" },
              { value: 16, label: "Predeterminado" },
              { value: 18, label: "Grande" },
              { value: 20, label: "Muy grande" },
            ]}
          />
          <div className="w-full flex justify-between px-1.5 mt-3">
            {/* Additional visual cues for specific steps */}
            {["12px", "14px", "16px", "18px", "20px"].map((size, index) => (
              <div
                key={size}
                className={`h-2.5 w-px ${
                  index === 2 ? "bg-black dark:bg-white w-0.5" : "bg-gray-400"
                }`}></div>
            ))}
          </div>
          <div className="*:w-3 dark:text-white *:text-center mt-1 *:flex *:items-center *:justify-center w-full flex justify-between text-xs">
            <span style={{ fontSize: "12px" }}>Aa</span>
            <span style={{ fontSize: "14px" }}></span>
            <span style={{ fontSize: "16px" }}>Aa</span>
            <span style={{ fontSize: "18px" }}></span>
            <span style={{ fontSize: "20px" }}>Aa</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SetupApperance;
