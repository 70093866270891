import React, { useState, useEffect, useMemo } from "react";

import Avatar from "react-avatar-edit";
import ProfileImage from "components/ui/profileImage";
import {
  Select,
  SelectValue,
  SelectTrigger,
  SelectContent,
  SelectItem,
} from "components/ui/select";
import { InputWithLabelAndError as Input } from "components/ui/InputWithLabelAndError"; // Assuming you have an Input component
import { Popover, PopoverContent, PopoverTrigger } from "components/ui/popover";
// import Checkbox from "components/ui/checkboxLabelError"; // Ensure the correct import path
import { Checkbox } from "components/ui/checkbox";
import { Button } from "components/ui/button";
import { Label } from "components/ui/label";
import { ReactComponent as EditIcon } from "icons/edit.svg";
import { ReactComponent as SaveIcon } from "icons/save.svg";
import { ReactComponent as CloseIcon } from "icons/close.svg";
import { ReactComponent as VerifiedIcon } from "icons/verified.svg";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "components/ui/tooltip";

import axios from "axios";
import { toast } from "sonner";
// Modal.setAppElement("#root"); //Adjust this to match your app root element

import { rolesWithLabels } from "data/translationArrays";
import { add } from "date-fns";

const roleLabelMap = new Map(
  rolesWithLabels.map((role) => [role.value, role.label])
);
const getRoleLabel = (roleValue) => {
  return roleLabelMap.get(roleValue) || roleValue; // Return the label or the original value if not found
};

const ExtendedProfile = ({
  userProfile,
  isEditable = false,
  setUserProfile,
  isEditingCommunity = false,
  verifyUser,
  verifyRole,
}) => {
  const [editMode, setEditMode] = useState(false);
  const [localProfile, setLocalProfile] = useState({ ...userProfile });

  const [userName, setUserName] = useState(userProfile.username);
  const [firstName, setFirstName] = useState(
    userProfile.contactDetails.firstName || ""
  );
  const [role, setRole] = useState(userProfile.role);
  const [workRole, setWorkRole] = useState(userProfile.contactDetails.workRole);
  const [lastName, setLastName] = useState(userProfile.contactDetails.lastName);
  const [email, setEmail] = useState(userProfile.email);
  // const [contactMail, setContactMail] = useState(
  //   userProfile.contactDetails.contactMail
  // );
  const [isEmailVerified, setIsEmailVerified] = useState(
    userProfile.contactDetails.isEmailVerified
  );
  const [isVerified, setIsVerified] = useState(userProfile.isVerified);
  const [lastLogin, setLastLogin] = useState(userProfile.lastLogin);
  const [firstLogin, setFirstLogin] = useState(userProfile.firstLogin);
  const [team, setTeam] = useState(userProfile.contactDetails.team);
  const [phone, setPhone] = useState(userProfile.contactDetails.phone);
  const [poligono, setPoligono] = useState(userProfile.poligono || "");
  const [parcela, setParcela] = useState(userProfile.parcela || "");
  const [catastro, setCatastral] = useState(userProfile.catastro || "");
  const [parcelaOrden, setParcelaOrden] = useState(userProfile.parcela_orden || "");
  const [whatsapp, setWhatsapp] = useState(
    userProfile.contactDetails.allowWhatsapp
  );
  const [company, setCompany] = useState(userProfile.contactDetails.company);
  const [address, setAddress] = useState(
    (userProfile.contactDetails &&
      userProfile.contactDetails.address &&
      userProfile.contactDetails.address.street) ||
      ""
  );
  const [city, setCity] = useState(
    (userProfile.contactDetails &&
      userProfile.contactDetails.address &&
      userProfile.contactDetails.address.city) ||
      ""
  );
  const [postalCode, setPostalCode] = useState(
    (userProfile.contactDetails &&
      userProfile.contactDetails.address &&
      userProfile.contactDetails.address.postalCode) ||
      ""
  );
  const [country, setCountry] = useState(
    (userProfile.contactDetails &&
      userProfile.contactDetails.address &&
      userProfile.contactDetails.address.country) ||
      ""
  );

  useEffect(() => {
    console.log(
      "ExtendedProfile catastro: ",
      catastro,
      "poligono: ",
      poligono,
      "parcela: ",
      parcela
    );
    console.log(
      "ExtendedProfile isVerified: ",
      isVerified,
      "isEmailVerified:",
      isEmailVerified,
      "lastLogin: ",
      lastLogin,
      "firstLogin: ",
      firstLogin
    );
    console.log(
      "ExtendedProfile team: ",
      team,
      "phone: ",
      phone,
      "whatsapp: ",
      whatsapp,
      "company: ",
      company
    );
    console.log(
      "ExtendedProfile address: ",
      address,
      "city: ",
      city,
      "postalCode: ",
      postalCode,
      "country: ",
      country
    );
    console.log(
      "ExtendedProfile workRole: ",
      workRole,
      "username: ",
      userName,
      "firstName: ",
      firstName,
      "lastName: ",
      lastName,
      "email: ",
      email
    );
  }, []);

  /**
   * const [address, setAddress] = useState(
    (userProfile.contactDetails &&
      userProfile.contactDetails.address &&
      userProfile.contactDetails.address.street) ||
      ""
  );
   */

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [preview, setPreview] = useState(null);
  const [src, setSrc] = useState(null);

  //---CHANGE PROFILE PICTURE / AVATAR
  //handlers for avatar editing/modals
  const openModal = () => setModalIsOpen(true);
  const closeModal = () => setModalIsOpen(false);

  const handleCrop = (preview) => {
    setPreview(preview);
  };

  // const handleClose = () => {
  //   setPreview(null);
  // };

  const handleBeforeFileLoad = (elem) => {
    if (elem.target.files[0].size > 71680) {
      // setMessage("Archivo demasiado grande");
      toast.error("Archivo demasiado grande!");
      // alert("File is too big!");
      elem.target.value = "";
    }
  };

  const handleUpload = async () => {
    if (!preview) return;

    const base64String = preview.replace("data:", "").replace(/^.+,/, "");

    try {
      const userId = JSON.parse(sessionStorage.getItem("userId"));

      const response = await axios.post(
        //"/api/handleUserProfile?action=updateProfilePicture",
        "https://0movft1iw8.execute-api.eu-west-1.amazonaws.com/dev/users/profile?action=updateProfilePicture",
        {
          userId,
          profilePicture: base64String,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        toast.success("Foto de actualizada satisfactoriamente");

        const updatedProfile = {
          ...localProfile,
          //profilePicture: response.data.profilePicture,
          profilePicture: preview,
        };

        setUserProfile(updatedProfile);
        setLocalProfile(updatedProfile);
        localStorage.setItem("userProfile", JSON.stringify(updatedProfile));
      } else {
        toast.error("Error con la actualización de la foto");
      }
      /*const updatedProfile = {
        ...localProfile,
        profilePicture: preview,  // Use the preview directly
      };

      setUserProfile(updatedProfile);
      setLocalProfile(updatedProfile);
      localStorage.setItem('userProfile', JSON.stringify(updatedProfile));
      setModalIsOpen(false);*/
    } catch (error) {
      toast.error("Error al actualizar la foto de perfil");
    }
  };

  //when the userProfile changes, the state should update accordingly
  useMemo(() => {
    setUserName(userProfile.username);
    setFirstName(userProfile.contactDetails.firstName);
    setLastName(userProfile.contactDetails.lastName);
    setEmail(userProfile.email);
    setIsEmailVerified(userProfile.isEmailVerified);
    setRole(userProfile.role);
    setIsVerified(userProfile.isVerified);
    setLastLogin(userProfile.lastLogin);
    setFirstLogin(userProfile.firstLogin);
    // Contact
    setParcela(userProfile.parcela || "");
    setCatastral(userProfile.catastro || "");
    setPoligono(userProfile.poligono || "");
    setParcelaOrden(userProfile.parcela_orden || "");
    // setContactMail(userProfile.contactDetails.contactMail);
    setPhone(userProfile.contactDetails.phone);
    setTeam(userProfile.contactDetails.team);
    setWhatsapp(userProfile.contactDetails.allowWhatsapp);
    setWorkRole(userProfile.contactDetails.workRole);
    setAddress(
      (userProfile.contactDetails &&
        userProfile.contactDetails.address &&
        userProfile.contactDetails.address.street) ||
        ""
    );
    setCity(
      (userProfile.contactDetails &&
        userProfile.contactDetails.address &&
        userProfile.contactDetails.address.city) ||
        ""
    );
    setPostalCode(
      (userProfile.contactDetails &&
        userProfile.contactDetails.address &&
        userProfile.contactDetails.address.postalCode) ||
        ""
    );
    setCountry(
      (userProfile.contactDetails &&
        userProfile.contactDetails.address &&
        userProfile.contactDetails.address.country) ||
        ""
    );
    setCompany(userProfile.contactDetails.company);
  }, [userProfile]);

  useEffect(() => {
    console.log("ExtendedProfile catastro: ", catastro);
  }, [catastro]);

  useEffect(() => {
    console.log("ExtendedProfile poligono: ", poligono);
  }, [poligono]);

  useEffect(() => {
    console.log("ExtendedProfile parcela: ", parcela);
  }, [parcela]);

  const handleSelectChange = (name) => (value) => {
    if ((name = "role")) {
      setRole(value);
    }
  };

  const handleCheckboxChange = () => {
    setWhatsapp((prevOptions) => !prevOptions);
  };

  const handleSave = async () => {
    //setUserProfile(localProfile);
    //setEditMode(false);
    let userId;
    try {
      if (!isEditingCommunity) {
        userId = JSON.parse(sessionStorage.getItem("userId"));
        console.log("ExtendedProfile userId localStorage: ", userId);
      } else {
        userId = userProfile.id;
        console.log("ExtendedProfile userId userProfile.id: ", userId);
      }

      const requestBody = {
        userId: userId,
        userName,
        firstName,
        lastName,
        email,
        phone,
        role,
        team,
        parcela,
        catastro,
        poligono,
        parcelaOrden,
        whatsapp,
        address,
        city,
        postalCode,
        country,
        workRole,
      };

      console.log("ExtendedProfile Request Body: ", requestBody);

      //Make the API call to save the profile data
      const response = await axios.post(
        //"/api/handleUserProfile?action=extendedProfile",
        "https://0movft1iw8.execute-api.eu-west-1.amazonaws.com/dev/users/profile?action=extendedProfile",
        {
          userId: userId, //userProfile.id
          userName,
          firstName,
          lastName,
          email,
          phone,
          role,
          team,
          parcela,
          catastro,
          poligono,
          parcelaOrden,
          whatsapp,
          address,
          city, //localProfile.contactDetails.city,
          postalCode, //localProfile.contactDetails.postalCode,
          country, //localProfile.contactDetails.country,
          workRole,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      // console.log("ExtendedProfile response: ", response);

      if (response.status === 200) {
        // setMessage("Perfil de usuario actualizado correctamente");
        toast.success("Perfil de usuario actualizado correctamente");
      } else {
        // setMessage("Error actualizando perfil de usuario");
        toast.error("Error actualizando perfil de usuario");
      }

      // Handle response if needed
      console.log("ExtendedProfile response: ", response);

      if (!isEditingCommunity) {
        //Update the user profile in the context and local state
        const updatedProfile = {
          ...localProfile,
          username: userName,
          parcela: parcela,
          poligono: poligono,
          catastro: catastro,
          contactDetails: {
            ...localProfile.contactDetails,
            firstName,
            lastName,
            // contactMail,
            phone,
            team,
            workRole,
            role,
            allowWhatsapp: whatsapp,
            address: {
              ...localProfile.contactDetails.address,
              street: address,
              city: city,
              postalCode: postalCode,
              country: country,
            },
          },
        };
        console.log("ExtendedProfile updatedProfile: ", updatedProfile);

        setUserProfile(updatedProfile);
        setLocalProfile(updatedProfile);

        // Update the user profile in the context
        //setUserProfile(localProfile);

        // Save updated profile to local storage
        // localStorage.setItem('userProfile', JSON.stringify(localProfile));
        // localStorage.setItem('contactDetails', JSON.stringify(localProfile.contactDetails));
        // localStorage.setItem('userSettings', JSON.stringify(localProfile.userSettings));
        localStorage.setItem("userProfile", JSON.stringify(updatedProfile));
        localStorage.setItem(
          "contactDetails",
          JSON.stringify(updatedProfile.contactDetails)
        );
        localStorage.setItem(
          "userSettings",
          JSON.stringify(updatedProfile.userSettings)
        );
      }

      setEditMode(false);
    } catch (error) {
      // setMessage("Error actualizando perfil de usuario");
      toast.error("Error actualizando perfil de usuario");
      // console.error("Error updating profile data:", error);
    }
  }; //End handleSave

  return (
    <div className="w-full h-full custom-scrollbar p-4 overflow-y-scroll dark:text-white bg-white dark:bg-gray-950 rounded-lg shadow-sm">
      <div className="flex pb-2 items-center">
        <h2>
          {firstName !== "N/A" && lastName !== "N/A" ? (
            <span>
              {firstName} {lastName}
            </span>
          ) : (
            "@" + userProfile.username
          )}
        </h2>
        {isEditable && editMode ? (
          <Popover open={modalIsOpen}>
            <PopoverTrigger asChild className="flex ml-auto relative">
              <Button
                onClick={() => setModalIsOpen(!modalIsOpen)}
                variant="ghost"
                className="px-1 group/profileButton hover:bg-transparent py-1 rounded-full h-fit  font-normal">
                <ProfileImage
                  size="xl"
                  image={userProfile.profilePicture}
                  firstName={userProfile.contactDetails.firstName}
                  lastName={userProfile.contactDetails.lastName}
                  userName={userProfile.username}
                />
                <span className="absolute opacity-0 group-hover/profileButton:opacity-100 transition-opacity bg-black/30 z-[10] flex items-center justify-center rounded-full inset-1">
                  <EditIcon className="size-10 text-white" />
                </span>
                <span
                  className={` ${
                    userProfile.isOnline ? "bg-green-500" : "bg-red-500"
                  } block top-1 right-1 absolute z-10 size-5  border-white dark:border-gray-950 border-2   rounded-full `}></span>
              </Button>
            </PopoverTrigger>
            <PopoverContent
              onEscapeKeyDown={closeModal}
              onInteractOutside={closeModal}
              className="flex flex-col gap-2 items-center mr-2">
              <div className=" rounded-lg hover:bg-gray-50 dark:hover:bg-gray-900">
                <Avatar
                  label="Seleccionar imagen"
                  width={200}
                  height={200}
                  onCrop={handleCrop}
                  onBeforeFileLoad={handleBeforeFileLoad}
                  src={src}
                />
              </div>
              {preview && (
                <img className="size-32 border" src={preview} alt="Preview" />
              )}
              <div className="flex w-full justify-between mt-4">
                <Button className="bg-green-600" onClick={handleUpload}>
                  Subir
                </Button>
                <Button className="bg-red-600" onClick={() => closeModal()}>
                  Cancelar
                </Button>
              </div>
            </PopoverContent>
          </Popover>
        ) : (
          <div className="p-1 flex ml-auto relative">
            <ProfileImage
              size="xl"
              image={userProfile.profilePicture}
              firstName={userProfile.contactDetails.firstName}
              lastName={userProfile.contactDetails.lastName}
              userName={userProfile.username}
            />
            <span
              className={` ${
                userProfile.isOnline ? "bg-green-500" : "bg-red-500"
              } block top-1 right-1 absolute z-10 size-5 group-hover/profileButton:border-gray-100 border-white dark:border-gray-950 border-2   rounded-full `}></span>
          </div>
        )}
      </div>
      {isEditable && editMode ? (
        <form
          className="w-full grid overflow-x-clip @container [&_label]:items-start [&_label]:pt-1 *:flex *:justify-start *:min-w-32 [&>span]:h-8 *:items-center [&_label]:text-base  [&:nth-chi ld(1)]:bg-orange-200 gap-x-4 gap-y-2 grid-cols-1 md:grid-cols-[auto,_1fr]"
          onSubmit={(e) => e.preventDefault()}>
          {/* <h3 className="col-span-2">Detalles de contacto</h3> */}
          <Label>Nombre:</Label>
          <Input
            //name="firstName"
            name="contactDetails.firstName"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
          />

          <Label>Apellido(s):</Label>
          <Input
            name="contactDetails.lastName"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
          />
          <Label>Nombre de usuario:</Label>
          <div className="flex justify-center gap-2 w-full">
            <span>@</span>
            <Input
              name="username"
              value={userName}
              onChange={(e) => setUserName(e.target.value)}
            />
            {/* Add button to verify user */}
          </div>

          <Label>Rol:</Label>
          <div className="gap-2 items-center flex flex-col @sm:flex-row relative">
            {isEditingCommunity ? (
              <>
                <span className="flex flex-row w-full gap-2">
                  <Select
                    className="w-fit max-w-[calc(100%_-_2rem)] mr-auto"
                    value={role}
                    onValueChange={handleSelectChange("role")}>
                    <SelectTrigger className="h-8 w-full ">
                      <SelectValue placeholder="Select role">
                        {getRoleLabel(role)}
                      </SelectValue>
                    </SelectTrigger>
                    <SelectContent>
                      {rolesWithLabels.map((role) => (
                        <SelectItem key={role.value} value={role.value}>
                          {role.label}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                  {isVerified && (
                    <TooltipProvider>
                      <Tooltip>
                        <TooltipTrigger className="ml-auto">
                          <span>
                            <VerifiedIcon className="size-5 text-blue-500" />
                          </span>
                        </TooltipTrigger>
                        <TooltipContent side="left">
                          <span>Usuario y Rol verificado</span>
                        </TooltipContent>
                      </Tooltip>
                    </TooltipProvider>
                  )}
                </span>
                {!isVerified && (
                  <Button
                    className="h-8 w-full @sm:w-fit hover:bg-blue-500"
                    onClick={verifyRole}>
                    Verificar
                  </Button>
                )}
              </>
            ) : (
              <>
                <span>{getRoleLabel(role)}</span>
                {isVerified && (
                  <div className="absolute w-fit h-8 flex items-center right-0 bg-gradient-to-l pl-5 from-white dark:from-gray-950 via-white dark:via-gray-950 to-transparent">
                    <TooltipProvider>
                      <Tooltip>
                        <TooltipTrigger className="ml-auto">
                          <span>
                            <VerifiedIcon className="size-5 text-blue-500" />
                          </span>
                        </TooltipTrigger>
                        <TooltipContent side="left">
                          <span>Usuario y Rol verificado</span>
                        </TooltipContent>
                      </Tooltip>
                    </TooltipProvider>
                  </div>
                )}
              </>
            )}
          </div>
          <Label>Equipo:</Label>
          <div className="flex flex-col w-full !justify-start !items-start gap-2">
            <Input
              name="contactDetails.team"
              className="w-full"
              value={team}
              onChange={(e) => setTeam(e.target.value)}
            />
          </div>

          <h3 className="md:col-span-2">Contacto</h3>
          <Label>Correo electronico:</Label>
          <div className="relative">
            {!isEditingCommunity ? (
              <>
                <Input
                  //name="email"
                  name="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </>
            ) : (
              <>
                <span>
                  <TooltipProvider>
                    <Tooltip>
                      <TooltipTrigger>
                        <span>{email}</span>
                      </TooltipTrigger>
                      <TooltipContent side="left">
                        <span> {email}</span>
                      </TooltipContent>
                    </Tooltip>
                  </TooltipProvider>
                </span>
                {isEmailVerified && (
                  <div className="absolute w-fit h-8 flex items-center right-0 bg-gradient-to-l pl-5 from-white dark:from-gray-950 via-white dark:via-gray-950 to-transparent">
                    <TooltipProvider>
                      <Tooltip>
                        <TooltipTrigger className="ml-auto">
                          <span>
                            <VerifiedIcon className="size-5 text-blue-500" />
                          </span>
                        </TooltipTrigger>
                        <TooltipContent side="left">
                          <span>Correo electronico verificado</span>
                        </TooltipContent>
                      </Tooltip>
                    </TooltipProvider>
                  </div>
                )}
              </>
            )}
          </div>

          {isEditingCommunity && (
            <>
              <Label className="self-start pt-[3px]">Teléfono:</Label>
              {isEditable && editMode ? (
                <div className="flex flex-col w-full   !justify-start items-center gap-2">
                  <Input
                    //name="phone"
                    name="contactDetails.phone"
                    containerClassName="w-full "
                    className="w-full "
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                  />
                  <span className="inline-flex mr-auto gap-2 items-center">
                    <Checkbox
                      name="whatsapp"
                      checked={whatsapp}
                      onCheckedChange={handleCheckboxChange}></Checkbox>
                    <Label>Permitir WhatsApp</Label>
                  </span>
                </div>
              ) : (
                <span>{phone}</span>
              )}
            </>
          )}

          <Label className="">Dirección:</Label>
          <div className="!grid gap-2 w-full @md:grid-cols-2">
            <Input
              name="contactDetails.street"
              placeholder="Calle y numero"
              value={address}
              //value={localProfile.contactDetails.street}
              onChange={(e) => setAddress(e.target.value)}
            />
            <Input
              name="contactDetails.city"
              placeholder="Ciudad"
              value={city}
              onChange={(e) => setCity(e.target.value)}
            />
            <Input
              name="contactDetails.postalCode"
              placeholder="Codigo postal"
              value={postalCode}
              onChange={(e) => setPostalCode(e.target.value)}
            />
            {/* <Input
                name="contactDetails.state"
                placeholder="State"
                value={localProfile.contactDetails.address.state}
                onChange={handleChange}
              /> */}
            <Input
              name="contactDetails.country"
              placeholder="País"
              value={country}
              onChange={(e) => setCountry(e.target.value)}
            />
          </div>
          <h3 className="md:col-span-2">Detalles de la Propriedad</h3>
          <Label>Parcela:</Label>
          <div className="flex flex-col w-full !justify-start !items-start gap-2">
            <Input
              name="contactDetails.parcela"
              className="w-full"
              value={parcela}
              onChange={(e) => setParcela(e.target.value)}
            />
          </div>

          <Label>Catastral:</Label>
          <div className="flex flex-col w-full !justify-start !items-start gap-2">
            <Input
              name="catastro"
              className="w-full"
              value={catastro}
              onChange={(e) => setCatastral(e.target.value)}
            />
          </div>

          <Label>Polígono:</Label>
          <div className="flex flex-col w-full !justify-start !items-start gap-2">
            <Input
              name="poligono"
              className="w-full"
              value={poligono}
              onChange={(e) => setPoligono(e.target.value)}
            />
          </div>
          {(firstLogin || lastLogin || isEditingCommunity) && (
            <h3 className="md:col-span-2">Actividad</h3>
          )}
          {lastLogin && (
            <>
          <Label>Último inicio de sesión:</Label>
              <span>
                {lastLogin !== "N/A"
                  ? new Intl.DateTimeFormat("es-ES", {
                      year: "2-digit",
                      month: "2-digit",
                      day: "2-digit",
                      hour: "2-digit",
                      minute: "2-digit",
                      hour12: false,
                    }).format(new Date(lastLogin))
                  : lastLogin}
              </span>
            </>
          )}
          {firstLogin && (
            <>
          <Label>Primer inicio de sesión:</Label>
              <span>
                {firstLogin !== "N/A"
                  ? new Intl.DateTimeFormat("es-ES", {
                      year: "2-digit",
                      month: "2-digit",
                      day: "2-digit",
                      hour: "2-digit",
                      minute: "2-digit",
                      hour12: false,
                    }).format(new Date(firstLogin))
                  : firstLogin}
              </span>
            </>
          )}

          <div className="flex md:col-span-2 gap-2">
            <Button
              className="col-span-2 pl-2.5 w-fit "
              variant="default"
              onClick={() => setEditMode(false)}>
              <CloseIcon className="size-6 pl-0 mr-2 p-0.5" />
              <span className="inline-flex">Cancelar</span>
            </Button>
            <Button
              className="col-span-2 pl-2.5 w-fit ml-auto bg-green-500 hover:bg-green-700"
              variant="default"
              onClick={handleSave}>
              <SaveIcon className="size-6 pl-0 mr-2 p-0.5" />
              <span className="inline-flex">Guardar</span>
            </Button>
          </div>
        </form>
      ) : (
        <div className="w-full grid overflow-x-clip [&_label]:items-start [&_label]:pt-1 *:flex *:justify-start *:min-w-32 [&>span]:h-8 *:items-center [&_label]:text-base  [&:nth-chi ld(1)]:bg-orange-200 gap-x-4 gap-y-2 grid-cols-1 md:grid-cols-[auto,_1fr]">
          <Label>Nombre:</Label>
          <span>{firstName}</span>
          <Label>Apellido(s):</Label>
          <span>{lastName}</span>
          <Label>Nombre de usuario:</Label>
          <span>@{userName}</span>
          <Label>Rol:</Label>

          <div className="relative">
            <span>{getRoleLabel(role)}</span>
            {isVerified && (
              <div className="absolute w-fit h-8 flex items-center right-0 bg-gradient-to-l pl-5 from-white dark:from-gray-950 via-white dark:via-gray-950 to-transparent">
                <TooltipProvider>
                  <Tooltip>
                    <TooltipTrigger className="ml-auto">
                      <span>
                        <VerifiedIcon className="size-5 text-blue-500" />
                      </span>
                    </TooltipTrigger>
                    <TooltipContent side="left">
                      <span>Usuario y Rol verificado</span>
                    </TooltipContent>
                  </Tooltip>
                </TooltipProvider>
              </div>
            )}
          </div>
          <Label>Equipo:</Label>
          <span>{team}</span>

          <h3 className="md:col-span-2">Contacto</h3>

          <Label>Correo electronico:</Label>
          <div className="relative ">
            <span>{email}</span>
            {isEmailVerified && (
              <div className="absolute w-fit h-8 flex items-center right-0 bg-gradient-to-l pl-5 from-white dark:from-gray-950 via-white dark:via-gray-950 to-transparent">
                <TooltipProvider>
                  <Tooltip>
                    <TooltipTrigger className="ml-auto">
                      <span>
                        <VerifiedIcon className="size-5 text-blue-500" />
                      </span>
                    </TooltipTrigger>
                    <TooltipContent side="left">
                      <span>Correo electronico verificado</span>
                    </TooltipContent>
                  </Tooltip>
                </TooltipProvider>
              </div>
            )}
          </div>
          <Label className="self-start pt-[3px]">Teléfono:</Label>
          <span>{phone}</span>
          <span className="md:col-span-2 !h-0"></span>
          {(address || city || postalCode || country) && (
            <>
              <Label className="">Dirección:</Label>
              <div className="flex-wrap gap-1 pb-8  w-full ">
                <span className="w-full">{address},</span>
                <div className="w-full space-x-1">
                  <span className="w-auto">{postalCode},</span>
                  <span className="w-auto">{city},</span>
                </div>
                <div className="w-full space-x-1">
                  {/* <span className="w-auto">
                    {localProfile.contactDetails.address.state},
                    </span> */}
                  <span className="w-auto">{country}</span>
                </div>
              </div>
            </>
          )}
          {(parcela || catastro || poligono || isEditingCommunity) && (
            <h3 className="md:col-span-2">Detalles de la Propriedad</h3>
  )}
          {(parcela || isEditingCommunity) && (
            <>
              <Label>Parcela:</Label>
              <span>{parcela}</span>
            </>
          )}
          {(catastro || isEditingCommunity) && (
            <>
              <Label>Catastral:</Label>
              <span>{catastro}</span>
            </>
          )}
          {(poligono || isEditingCommunity) && (
            <>
              <Label>Polígono:</Label>
              <span>{poligono}</span>
            </>
          )}
  {(firstLogin || lastLogin || isEditingCommunity) && (
    <h3 className="md:col-span-2">Actividad</h3>
  )}

          {firstLogin && (
            <>
              <Label>Primer inicio de sesión:</Label>
              <span>
                {firstLogin !== "N/A"
                  ? new Intl.DateTimeFormat("es-ES", {
                      year: "2-digit",
                      month: "2-digit",
                      day: "2-digit",
                      hour: "2-digit",
                      minute: "2-digit",
                      hour12: false,
                    }).format(new Date(firstLogin))
                  : firstLogin}
              </span>
            </>
          )}
          {lastLogin && (
            <>
              <Label>Último inicio de sesión:</Label>
              <span>
                {lastLogin !== "N/A"
                  ? new Intl.DateTimeFormat("es-ES", {
                      year: "2-digit",
                      month: "2-digit",
                      day: "2-digit",
                      hour: "2-digit",
                      minute: "2-digit",
                      hour12: false,
                    }).format(new Date(lastLogin))
                  : lastLogin}
              </span>
            </>
          )}
          <Button
            className="col-span-2 pl-2.5 w-fit ml-auto"
            variant="default"
            onClick={() => setEditMode(true)}>
            <EditIcon className="size-6 pl-0 p-1 mr-2" />
            <span className="inline-flex">Editar perfil</span>
          </Button>
        </div>
      )}
      {/* {message && (
        <div className="mt-4 p-2 text-center text-green-500 ">{message}</div>
      )} */}
    </div>
  );
};

export default ExtendedProfile;
