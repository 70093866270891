import React, { useEffect, useState } from "react";

import { useAuth } from "contexts/AuthContext";
import { usePageTitle } from "contexts/PageTitleContext";
import { useStateContext } from "contexts/ContextProvider";

import { rolesWithLabels } from "data/translationArrays";
import { ReactComponent as ProfileIcon } from "icons/profile.svg";
import { ReactComponent as HelpIcon } from "icons/help.svg";
import { ReactComponent as FeedbackIcon } from "icons/feedback.svg";
import { ReactComponent as CommunityIcon } from "icons/community.svg";
import { ReactComponent as SettingsIcon } from "icons/settings.svg";
import { ReactComponent as LogoutIcon } from "icons/logout.svg";
import { ReactComponent as MoonIcon } from "icons/moon.svg";
import { ReactComponent as SunIcon } from "icons/sun.svg";
import { ReactComponent as ArrowRightIcon } from "icons/arrow/right.svg";
import ProfileImage from "components/ui/profileImage";
import Help from "components/help";
import Feedback from "components/feedback";

import { Button } from "components/ui/button";
import { Popover, PopoverContent, PopoverTrigger } from "components/ui/popover";
import {
  Sheet,
  SheetContent,
  SheetDescription,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from "components/ui/sheet";
import { DarkmodeButton } from "components";
// import { useHandleSetLayout } from "contexts/functions/layoutFunctions";

const WindowUserCard = () => {
  const storedUserProfile = JSON.parse(localStorage.getItem("userProfile"));
  //console.log("WindowUserCard storedUserProfile: ",storedUserProfile);


  const {
    userProfile,
    toggleDarkMode,
    setActiveComponent,
    setActiveLinkName,
    logoutRemoveProfile,
    handleSetLayout,
  } = useStateContext();
  // const handleSetLayout = useHandleSetLayout(); // Use the custom hook
  const [userIsAGuest, setUserIsAGuest] = useState(false);
  useEffect(() => {
    if (userProfile && userProfile.isUserAGuest && userProfile.isUserAGuest === true ) {
      setUserIsAGuest(true);
    }
  }, [userProfile]);

  // useEffect(() => {
  //   console.log("storedUserProfile in WindowUserCard", storedUserProfile);
  // }, [storedUserProfile]);

  // useEffect(() => {
  //   console.log("userProfile in WindowUserCard", userProfile);
  // }, [userProfile]);

  const { handleLogout } = useAuth(); //Get handleLogout from AuthContext
  // const navigate = useNavigate(); //Initialize navigate hook
  const { setPageTitle } = usePageTitle();

  const logoutHandler = () => {
    handleLogout();
    logoutRemoveProfile();
    setActiveComponent("login");
  };

  const handleGoToRegister = () => {
    setActiveLinkName("login");
    setPageTitle("");
    setActiveComponent("register");
  };

  //TEST to try to fix "storedUserProfile is null WindowUserCard..." error
  if (!storedUserProfile || !userProfile) {
    return null; // or some fallback UI
  }

  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button
          id="quicksettings"
          className="height-8 p-0 relative md:height-10 rounded-full items-center justify-center flex
              after:content-[''] sm:pr-1 space-x-2 after:transition-colors after:-inset-1 after:rounded-full after:z-0 after:hover:bg-gray-100 dark:after:hover:bg-gray-800 after:absolute"
          variant="primary">
          <ProfileImage
            image={userProfile.profilePicture}
            firstName={userProfile.contactDetails.firstName}
            lastName={userProfile.contactDetails.lastName}
            userName={userProfile.username}
            size="sm"
            isUserAGuest={storedUserProfile.isUserAGuest}
          />
          {userProfile.contactDetails.firstName && (
            <div className="hidden sm:flex flex-col pr-2 z-[1] text-left w-full">
              <span className="text-xs dark:text-gray-50 font-semibold">
                {userProfile.contactDetails.firstName}{" "}
                {userProfile.contactDetails.lastName}
              </span>
              {userProfile.role && (
                <span className="text-xs text-gray-400 dark:text-gray-300">
                  {rolesWithLabels.map((role) => {
                    if (role.value === userProfile.role) {
                      return role.label;
                    }
                  })}
                </span>
              )}
            </div>
          )}
          {userIsAGuest && (
            <span className="text-xs z-[1] dark:text-gray-50 font-semibold">
              Invitado
            </span>
          )}
        </Button>
      </PopoverTrigger>
      <PopoverContent className="p-0 w-fit m-2">
        <div className="flex flex-col space-y-4 p-4 md:p-6">
          <div className="flex flex-row space-x-2">
            <ProfileImage
              size="lg"
              image={userProfile.profilePicture}
              firstName={userProfile.contactDetails.firstName}
              lastName={userProfile.contactDetails.lastName}
              userName={userProfile.username}
              isUserAGuest={userIsAGuest}
            />
            <div className="flex flex-col justify-center w-full">
              <span className="text-lg font-semibold">
                {userProfile.contactDetails.firstName &&
                  userProfile.contactDetails.firstName +
                    " " +
                    userProfile.contactDetails.lastName}
                {userIsAGuest && (
                  <span className="text-lg font-semibold">Invitado</span>
                )}
              </span>

              {userProfile.role && (
                <span className="text-xs text-gray-400 dark:text-gray-300">
                  {rolesWithLabels.map((role) => {
                    if (role.value === userProfile.role) {
                      return role.label;
                    }
                  })}
                </span>
              )}
              {userProfile.email &&
              <a
              className="text-[0.6rem] w-fit text-blue-500 dark:text-blue-400"
              href="#">
                {userProfile.email}
              </a>
                }
            </div>
            <div className="sm:relative flex items-center">
              <DarkmodeButton />
              {/* <Button
                variant="ghost"
                className={`focus-visible:outline-0 relative size-8 md:size-10 p-1 sm:p-1 md:p-2 rounded-full after:content-[''] after:transition-colors after:-inset-1 after:rounded-full after:z-0 after:hover:bg-gray-100 dark:after:hover:bg-gray-800 after:absolute dark:text-gray-100 after:hover:text-green-500 transition-colors items-center justify-center flex`}
                onClick={toggleDarkMode}>
                {userProfile.darkMode ? (
                  <MoonIcon className="size-full z-10" />
                ) : (
                  <SunIcon className="size-full z-10" />
                )}
              </Button> */}
            </div>
          </div>
          <div className="grid grid-cols-3 gap-4 grid-rows-2">
            {/* If user is not guest, show this */}
            {!userIsAGuest &&
              storedUserProfile.role !== "guest" && (
                <>
                  {[
                    {
                      icon: SettingsIcon,
                      link: "settings",
                      text: "Ajustes",
                      name: "Settings",
                    },
                    {
                      icon: ProfileIcon,
                      link: "profile",
                      text: "Perfil",
                      name: "Profile",
                    },
                    {
                      icon: CommunityIcon,
                      link: "community",
                      text: "Comunidad",
                      name: "Community",
                    },
                  ].map((item, index) => (
                    <div
                      key={index}
                      className="flex flex-col justify-center space-y-2 items-center">
                      <button onClick={() => handleSetLayout(item.name)}>
                        <item.icon className="rounded-full dark:bg-gray-1000 bg-gray-100 hover:bg-gray-200 dark:hover:bg-gray-700 transition-colors p-2.5 size-12" />
                      </button>
                      <span className="text-xs text-center">{item.text}</span>
                    </div>
                  ))}
                </>
              )}
            {userIsAGuest && (
              <div className="col-span-3 flex items-center justify-between">
                Registrate ahora
                <Button onClick={handleGoToRegister} size="icon">
                  <ArrowRightIcon />
                </Button>
              </div>
            )}
            {storedUserProfile && storedUserProfile.role === "guest" && (
              <div className="col-span-3 flex px-3 text-center items-center justify-center">
                <span className="rounded-full w-full text-sm flex items-center justify-center dark:bg-gray-950 bg-gray-100 transition-colors p-2.5 h-12">
                  Verificacion de rol pendiente
                </span>
              </div>
            )}
            {[
              {
                icon: HelpIcon,
                link: "help",
                text: "Ayuda",
                name: "help",
                isSheet: true,
                sheetContent: <Help />,
              },
              {
                icon: FeedbackIcon,
                link: "feedback",
                text: "Feedback",
                name: "Feedback",
                isSheet: true,
                sheetContent: <Feedback />,
              },
              {
                icon: LogoutIcon,
                link: "logout",
                onClick: logoutHandler,
                text: "Cerrar sesión",
                isLogout: true,
              },
            ].map((item, index) => (
              <div
                key={index}
                className="flex flex-col justify-center space-y-2 items-center">
                {item.isLogout ? (
                  <>
                    <button onClick={logoutHandler}>
                      <item.icon className="rounded-full dark:bg-gray-1000 bg-gray-100 hover:bg-gray-200 dark:hover:bg-gray-700 transition-colors p-2.5 size-12" />
                    </button>
                    <span className="text-xs text-center">Cerrar sesión</span>
                  </>
                ) : item.isSheet ? (
                  <>
                    <Sheet>
                      <SheetTrigger asChild>
                        <button>
                          <item.icon className="rounded-full dark:bg-gray-1000 bg-gray-100 hover:bg-gray-200 dark:hover:bg-gray-700 transition-colors p-2.5 size-12" />
                        </button>
                      </SheetTrigger>
                      <SheetContent className="rounded-l-2xl sm:!max-w-lg md:!max-w-xl">
                        <SheetHeader>
                          <SheetTitle>{item.text}</SheetTitle>
                          <SheetDescription>
                            {item.text === "Ayuda"
                              ? "Aqui puedes encontrar ayuda sobre el uso de la aplicación"
                              : "Proporcione sus comentarios y sugerencias"}
                          </SheetDescription>
                          {item.sheetContent}
                        </SheetHeader>
                      </SheetContent>
                    </Sheet>
                    <span className="text-xs text-center">{item.text}</span>
                  </>
                ) : (
                  <>
                    <button onClick={() => handleSetLayout(item.name)}>
                      <item.icon className="rounded-full dark:bg-gray-1000 bg-gray-100 hover:bg-gray-200 dark:hover:bg-gray-700 transition-colors p-2.5 size-12" />
                    </button>
                    <span className="text-xs text-center">{item.text}</span>
                  </>
                )}
              </div>
            ))}
          </div>
        </div>
      </PopoverContent>
    </Popover>
  );
};

export default WindowUserCard;
