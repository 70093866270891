import React from "react";
import { ResponsiveDataTable } from "components/widgets/widgetTypes/tables/ResponsiveDataTable";
// import { useHandleSetLayout } from "contexts/functions/layoutFunctions";
import { useStateContext } from "contexts/ContextProvider";
const AnalyticsSensorTable = ({
  onRowSelectionChange,
  showHeaders,
  toggleDataSelector,
  data,
  // options,
  // columnsConfig,
  // filterFor,
  // filterForLabel
}) => {
  /* console.log("AnalyticsSensorTable data: ",data);
  console.log("AnalyticsSensorTable options: ",options);
  console.log("AnalyticsSensorTable data: ",data); */
  const filterFor = "deviceId"
  const filterForLabel = "Dispositivo"
  // const handleSetLayout = useHandleSetLayout();
  const { handleSetLayout } = useStateContext();

  const selectRow = (selectedRowIds) => {
    //console.log("AnalyticsSensorTable Selected row IDs:", selectedRowIds);
    onRowSelectionChange(selectedRowIds);
    if (selectedRowIds.length === 0) {
      handleSetLayout("Analytics", "analytics"); // Switch to original state if no rows are selected
    } else {
      handleSetLayout("Analytics", "anExpanded"); // Specify the link name and the layout name
    }
  };

  const options = {
    city: "Valencia",
    location: "Marjals i Extremals",
    sensors: ["Entrada Alqueria Ros"],
    columns: ["message", "location", "updated", "urgency", "assigned", "status"], // Specifying which columns to show by 

    categories: {
      pressure: { minValue: 0, maxValue: 100 },
      temperature: { minValue: 0, maxValue: 50 },
      flow: { minValue: 0, maxValue: 30 }
    }
  }
  // console.log("data in AnalyticsSensorTable", data)

  const localColumnsConfig =  [
    { id: "select", type: "select" },
    // {
    //   id: "deviceId",
    //   type: "sortable",
    //   accessorKey: "deviceId",
    //   header: "Id",
    // },
    {
      id: "title",
      type: "sortable",
      accessorKey: "title",
      header: "Sensor",
    },
  ];

  return (
    <>
      <ResponsiveDataTable
        showHeaders={showHeaders}
        toggleDataSelector={toggleDataSelector}
        data={data}
        options={options}
        columnsConfig={localColumnsConfig}
        filterFor={filterFor}
        filterForLabel={filterForLabel}
        enableRowSelection={true}
        // singleRowSelection={true}
        onRowSelectionChange={selectRow}
      />
    </>
  );
};

export default AnalyticsSensorTable;
