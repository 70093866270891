import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import { Icon } from "@iconify/react";
import Logo from "logo.svg";
import { InputWithLabelAndError } from "components/ui/InputWithLabelAndError";
import { useStateContext } from "contexts/ContextProvider";

const ForgotPasswordFrame = () => {

  console.log("ForgotPasswordResetFrame inside");
  const [formData, setFormData] = useState({
    email: "",
    newPassword: "",
    confirmPassword: "",
  });

  const [message, setMessage] = useState("");
  const [errors, setErrors] = useState({});

  const location = useLocation();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false); // To track loading state
  const { setActiveComponent } = useStateContext();

    //Extract the token from URL query parameters

  const token = new URLSearchParams(location.search).get("resToken");

  // NO TOKEN REROUTING
  // useEffect(() => {
  //     if (!token) {
  //         setMessage('Token de restablecimiento no proporcionado o inválido.');
  //         setTimeout(() => navigate('/login'), 3000);
  //     }
  // }, [navigate, token]);

  const validate = () => {
    let isValid = true;
    let newErrors = {};

    if (!formData.email) {
      isValid = false;
      newErrors.email = "El email es necesario";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      isValid = false;
      newErrors.email = "El email no es válido";
    }

    if (!formData.newPassword) {
      isValid = false;
      newErrors.newPassword = "Se necesita una nueva contraseña.";
    }

    if (formData.newPassword !== formData.confirmPassword) {
      isValid = false;
      newErrors.confirmPassword = "Las contraseñas no coinciden.";
    }

    setErrors(newErrors);
    return isValid;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validate()) {
      setIsLoading(true); //Indicate loading state
      try {
        //const response = await fetch("/api/passwordReset", {
        const response = await fetch("https://0movft1iw8.execute-api.eu-west-1.amazonaws.com/dev/auth/passwordReset", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            email: formData.email,
            resetToken: token,
            newPassword: formData.newPassword,
          }),
        });
        console.log("ForgotPasswordResetFrame response", response);
        const data = await response.json();
        console.log("ForgotPasswordResetFrame data", data);

        if (data.statusCode >=200 && data.statusCode < 300) {
          setMessage("Nueva contraseña creada con éxito.");
          setTimeout(() => {
            //navigate("/login");
            //setActiveComponent("login");
            window.location.href = "/"
          }, 3000);
        } else {
          //
          /*const data = await response.json();
          setMessage(
            data.message || "Ocurrió un error al restablecer la contraseña."
          );*/
          //
          const parsedBody = JSON.parse(data.body);
          console.log("ForgotPasswordResetFrame parsedBody: ",parsedBody);
          let errorMessage = "Error durante registro";
          switch (data.statusCode) {
            case 400:
              errorMessage = parsedBody.message;
              break;
            case 500:
              errorMessage = parsedBody.message;
              break;
            default:
              errorMessage = `Error inesperado: ${response.statusText}`;
          }
          console.error("Registration error:", data.message);
          setMessage(errorMessage);
        }
      } catch (error) {
        console.error("Request error:", error);
        setMessage("Error conectando con el servidor");
      } finally {
        setIsLoading(false); // Reset loading state
      }
    }
  };

  return (
    <div className="max-w-md w-full flex flex-col gap-y-4 md:gap-y-8">
        <h2 className=" font-medium leading-7 dark:text-white">
          Crear nueva contraseña
          {/* Create a new Password */}
        </h2>
        <form className="flex flex-col gap-y-4" onSubmit={handleSubmit}>
          <InputWithLabelAndError
            value={formData.email}
            onChange={handleChange}
            type="email"
            name="email"
            label="Email"
            placeholder="johndoe@example.com"
            hasFloatingLabel={false}
            error={errors.email}
          />

          <InputWithLabelAndError
            value={formData.newPassword}
            onChange={handleChange}
            type="password"
            name="newPassword"
            label="Nueva Contraseña"
            placeholder="••••••••"
            hasFloatingLabel={false}
            error={errors.newPassword}
          />

          <InputWithLabelAndError
            value={formData.confirmPassword}
            onChange={handleChange}
            type="password"
            name="confirmPassword"
            label="Confirma Nueva Contraseña"
            placeholder="••••••••"
            hasFloatingLabel={false}
            error={errors.confirmPassword}
          />

          <button
            type="submit"
            className="mt-2 w-full bg-[#1D91E0] text-white py-2 px-4 rounded-md hover:bg-blue-600 focus:outline-none focus:bg-blue-600">
            Create new password
            {/* Crear Nueva Contraseña */}
          </button>

          {/* <input
            type="submit"
            value="Crear Nueva Contraseña"
            className={`bg-dark-blue-700 w-full py-3 text-white uppercase font-bold rounded ${
              isLoading
                ? "opacity-50 cursor-not-allowed"
                : "hover:cursor-pointer hover:bg-sky-500"
            } transition-colors`}
            disabled={isLoading}
          /> */}
        </form>

        {message && (
          <p
            className={`text-${
              message.startsWith("Error") ? "red" : "green"
            }-500`}>
            {message}
          </p>
        )}
        <div className="lg:flex lg:justify-center">
          <p className="text-sm dark:text-white text-center ">
            <span>Back to</span>
            <Link
              className="inline-block ml-1 text-center text-[#1D91E0] hover:text-blue-600"
              to="/login"  
              onClick={() => window.location.href = "/"}> {/**setActiveComponent('login') */}
              Login
            </Link>
          </p>
        </div>
      </div>
  );
};

export default ForgotPasswordFrame;
