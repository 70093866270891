import React, { memo, useState, useEffect, useRef } from "react";
import { useAuth } from "contexts/AuthContext";
import { Icon } from "@iconify/react";
import { ButtonGroup } from "@mui/material";
import { Button } from "components/ui/button";
import { ReactComponent as PlusIcon } from "icons/plus.svg";
import { ReactComponent as LoadingMap } from "icons/loading-map.svg";
import proj4 from "proj4";
import { useStateContext } from "contexts/ContextProvider";
import { hasPermission } from "components/authentication/hasPermission";
import { Progress } from "components/ui/progress";
const MapGis = memo(({ data, options, toggleDataSelector, onRowSelectionChange, selectedRowIds }) => {
  const { authAccessToken } = useAuth();
  const iframeRef = useRef(null);
  const wgs84 = "EPSG:4326";
  const utmZone30 = "+proj=utm +zone=30 +datum=WGS84 +units=m +no_defs";
  const { activeLinkName, isLoadingUserPermissions, setIsLoadingUserPermissions } = useStateContext();
  
  const [zoom, setZoom] = useState(13.738435055749529);
  const [coordx, setCoordx] = useState(726905.9995937144);
  const [coordy, setCoordy] = useState(4369653.509639962);
  // const [visibleLayers, setVisibleLayers] = useState([]); // will be gmaps21
  const [mapa, setMapa] = useState("gmap22"); // gmap21 = basico, gmap22 = gestor or gmap23 = estado
  const canEditMap = hasPermission("map.edit", isLoadingUserPermissions, setIsLoadingUserPermissions);
  const canViewFullMap = hasPermission("fullMap.view", isLoadingUserPermissions, setIsLoadingUserPermissions);
  
  useEffect(() => {
    // if (canViewFullMap) {
    //   setMapa("gmap21");
    // } else 
    if (canEditMap) {
      setMapa("gmap22");
    } else 
      setMapa("gmap21");
  }, [canEditMap, canViewFullMap]);

  // useEffect(() => {
  //   if (canViewFullMap) {
  //     setMapa("gmap21");
  //   } else if (canEditMap) {
  //     setMapa("gmap22");
  //   } else 
  //     setMapa("gmap23");
  // }, [canEditMap, canViewFullMap]);
  
  const [isLoadingMap, setIsLoadingMap] = useState(false);
  const [isDelayOver, setIsDelayOver] = useState(false);
  const [loadingProgress, setLoadingProgress] = useState(0);
  const loadingDuration = 7000;
  const intervalDuration = 300;

  const generateRandomIncrements = (total, length) => {
    const arr = Array.from({ length }, () => Math.random());
    const sum = arr.reduce((acc, value) => acc + value, 0);
    return arr.map(value => (value / sum) * total);
  };

  useEffect(() => {
    let increments = [];
    if (isLoadingMap || !isDelayOver) {
      increments = generateRandomIncrements(100, loadingDuration / intervalDuration);
    }
    let timer;
    let index = 0;
    if (increments.length > 0) {
      timer = setInterval(() => {
        setLoadingProgress(prevProgress => {
          if (index >= increments.length) {
            clearInterval(timer);
            return 100;
          }
          const newProgress = prevProgress + increments[index];
          index++;
          return Math.min(newProgress, 100);
        });
      }, intervalDuration);
    }
    return () => clearInterval(timer);
  }, [isLoadingMap, isDelayOver, loadingDuration, intervalDuration]);


  useEffect(() => {
    if (selectedRowIds.length > 0) {
      const selectedSensors = selectedRowIds
        .map(rowId => data.find(entry => entry.id === rowId))
        .filter(sensor => sensor !== undefined);
      //console.log("MapGis: selectedSensors", selectedSensors);

      const sumCoords = selectedSensors.reduce(
        (acc, sensor) => {
          acc[0] += sensor.location.coordinates[0];
          acc[1] += sensor.location.coordinates[1];
          return acc;
        },
        [0, 0]
      );

      const newCoordx = sumCoords[0] / selectedSensors.length;
      const newCoordy = sumCoords[1] / selectedSensors.length;

      // const pixelToCoordFactorX = 0; // Adjust this factor based on your actual map scale
      // const pixelToCoordFactorY = 0.2; // Adjust this factor based on your actual map scale
      // const offsetX = 600 * pixelToCoordFactorX; // 9 pixels
      // const offsetY = 58 * pixelToCoordFactorY; // 58 pixels


      // setCoordx(newCoordx - offsetX);
      // setCoordy(newCoordy - offsetY);

      // console.log("MapGis: newCoordx", newCoordx);
      // console.log("MapGis: newCoordy", newCoordy);

      // console.log("MapGis: coordX", coordx);
      // console.log("MapGis: coordY", coordy);
      setCoordx(newCoordx);
      setCoordy(newCoordy);

      const newZoom = selectedSensors.length === 1 ? 17 : 15;
      setZoom(newZoom);
    }
  }, [selectedRowIds, data]);

  useEffect(() => {
    const timer = setTimeout(() => setIsDelayOver(true), loadingDuration);
    return () => clearTimeout(timer);
  }, []);

  // TODO Hide Console.logs from MapGis
  // useEffect(() => {
  //   if (iframeRef.current) {
  //     iframeRef.current.onload = () => {
  //       const iframeWindow = iframeRef.current.contentWindow;
  //       if (iframeWindow) {
  //         iframeWindow.console.log = function() {
  //           // Override to do nothing
  //         };
  //       }
  //     };
  //   }
  // }, []);
  // END TODO

  const handleZoomIn = (e) => {
    e.preventDefault();
    setZoom(prevZoom => Math.min(prevZoom + 1, 20)); // Assuming 20 is the max zoom level
    setIsLoadingMap(true);
    setTimeout(() => {
      setIsLoadingMap(false);
    }, 800);
  };

  const handleZoomOut = (e) => {
    e.preventDefault();
    setIsLoadingMap(true);
    setTimeout(() => {
      setIsLoadingMap(false);
    }, 800);
    setZoom(prevZoom => Math.max(prevZoom - 1, 0)); // Assuming 0 is the min zoom level
  };

  const handleGetLocation = (e) => {
    e.preventDefault();
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          const [utmX, utmY] = proj4(wgs84, utmZone30, [longitude, latitude]);
          setCoordx(utmX);
          setCoordy(utmY);
        },
        (error) => {
          console.error("Error getting location:", error);
        }
      );
    } else {
      console.error("Geolocation is not supported by this browser.");
    }
  };

  const [isFullMap, setIsFullMap] = useState(false);

  useEffect(() => {
    if (activeLinkName === "maps" && canEditMap) {
      setIsFullMap(true);
    } else {
      setIsFullMap(false);
    }
    // console.log("MapGis: activeLinkName", activeLinkName);
  }, [activeLinkName]);

  return (
    <div
      id="iFrame-parent"
      className="p-0 overflow-clip min-h-20 w-full relative h-full"
    >
      {(isLoadingMap || !isDelayOver) && (
        <div className="absolute flex items-center justify-center inset-0 pointer-events-none z-20 bg-white dark:bg-gray-950">
          <Progress value={loadingProgress} className="z-20 absolute w-10/12 mx-auto h-3 left-1/2 top-1/2 max-w-md -translate-x-1/2 -translate-y-1/2" />
          <div className="w-full h-full opacity-50 invert dark:invert-0">

            <LoadingMap className="w-full animate-pulse-slow object-cover rounded-md h-full text-gray-950" />
          </div>
        </div>
      )}
      {!isFullMap && (
        <>
          <ButtonGroup className="flex flex-col absolute top-2 left-2 z-[11] shadow-sm">
            <Button
              className="size-8 p-1 bg-white text-gray-600 dark:text-gray-200 hover:text-white dark:hover:text-white dark:bg-gray-900 rounded-b-none"
              size="icon"
              variant="default"
              onClick={handleZoomIn}
            >
              <Icon icon="mdi:plus" className="size-6" />
            </Button>
            <Button
              className="size-8 p-1 bg-white text-gray-600 dark:text-gray-200 hover:text-white dark:hover:text-white dark:bg-gray-900 rounded-t-none border-t-gray-500 border-t dark:border-t-gray-1000"
              size="icon"
              variant="default"
              onClick={handleZoomOut}
            >
              <Icon icon="mdi:minus" className="size-6" />
            </Button>
          </ButtonGroup>
          <Button
            className="size-8 p-1 absolute bottom-2 z-[11] right-2 bg-white text-gray-600 dark:text-gray-200 hover:text-white dark:hover:text-white dark:bg-gray-900"
            size="icon"
            variant="default"
            onClick={handleGetLocation}
          >
            <Icon icon="mdi:crosshairs-gps" className="size-6" />
          </Button>
        </>
      )}
      <div className="absolute inset-0 pointer-events-none z-10 dark:backdrop-brightness-110 backdrop-saturate-125 dark:backdrop-hue-rotate-[170deg] dark:backdrop-invert-[90%] dark:backdrop-grayscale-[30%]" />
      <div className="h-full w-full flex overflow-clip rounded-sm">
        <div className="w-full h-full overflow-clip">
          {/* <div className="absolute bg-transparent h-[50px] w-full border border-white pointer-events-none">
          </div> */}
          <iframe
            id="map-iframe"
            ref={iframeRef}
            src={`https://sig.fibsen.com/visor4/?mapa=${mapa}&zi=${zoom}&coordx=${coordx}&coordy=${coordy}&token=${authAccessToken}`} 
            title="GIS Map"
            sandbox="allow-same-origin allow-scripts allow-popups-to-escape-sandbox"
            className={`${canEditMap && isFullMap ? "w-full h-full" : "w-[calc(100%_+_600px)] -translate-x-[300px] h-[calc(100%_+_116px)] -translate-y-[58px]"}`}
          ></iframe>
        </div>
      </div>
    </div>
  );
});

export default MapGis;
