import axios from 'axios';
import { widgetData } from "data/chartData/frontLayout";
import { frontLayouts } from "data/frontLayouts";

const loadUserProfile = async (setUserProfile, userId, userProfile, setIsLoadingUserPermissions) => {
  try {
    //Retrieve userId
    //const userId = sessionStorage.getItem('userId');
    //console.log("loadUserProfile userId:", userId);
    //console.log("loadUserProfile userProfile onLoad:", userProfile);

    // Ensure userId is defined
    if (!userId) {
      console.log("loadUserProfile user ID not found in session storage");
      return;
    }
    //https://0movft1iw8.execute-api.eu-west-1.amazonaws.com/dev/
    //const response = await axios.post('/api/handleUserProfile?action=getProfile', {
    const response = await axios.post('https://0movft1iw8.execute-api.eu-west-1.amazonaws.com/dev/users/profile?action=getProfile', {
      userId
    }, {
      headers: {
        'Content-Type': 'application/json'
      }
    });

    //const data = response.data;
    //Original name: data. New name: parsedData. 
    //Due to permissions being sent along userProfile and to not alter too much the previous structure this variable's name is changed. 
    //IF in the future permissions is not sent anymore, change the name back to data and remove the line const { userProfile: profile, permissions } = data;
    const parsedData = JSON.parse(response.data.body);
    //console.log("loadUserProfile response: ", response);
    console.log("loadUserProfile fetch user's profile data:", parsedData); //Original name data

    //UPDATE: temporarily permissions is sent from the backend along with userProfile
    //this line is needed to extract the profile without altering much the previous structure.
    //Name data added to userProfile to not change too much the previous logic.
    //Extract the userProfile and permissions from the response

    const { userProfile: data, permissions } = parsedData;

    //Extract and structure the data
    const profileData = {
      username: data.user_name || '',
      role: data.role_name || '', // Map this appropriately
      //password: data.user_password || null,
      parcela: data.parcel_queue_id || null,
      poligono: data.polygon_queue_id || null,
      catastro: data.cadastre || "",
      parcela_orden: data.order || null,
      isUserAGuest: false, // not in db
      phone: data.user_phone || '',
      email: data.user_email || '',
      profilePicture: data.user_photo || '', // not yet
      contactDetails: {
        firstName: data.first_name || '',
        lastName: data.last_name || '',
        workRole: data.workRole || '', // not in db

        bio: data.bio || '', // not in db
        phone: data.user_phone || '',
        contactMail: data.user_email || '',
        allowWhatsapp: data.allow_whatsapp !== undefined ? data.allow_whatsapp : '',
        address: {
          city: data.city || '', // not in db
          state: data.state || '', // not in db
          country: data.country || '', // not in db
          postalCode: data.postal_code || '', // not in db
          street: data.adress || '',
        },
        // social: {
        //   facebook: "https://www.facebook.com/",
        //   linkedin: "https://www.linkedin.com/in/",
        //   twitter: "https://www.twitter.com/",
        //   website: "",
        // },
      },
      userSettings: {
        darkMode: data.dark_mode !== undefined ? data.dark_mode : '',
        dashboardLayout: data.dashboardLayout || frontLayouts["simple"], // should be "example-1 for demo" //sorted
        widgets: data.widgets || widgetData,
        layoutMode: data.layoutMode || "simple",
        // Add other settings as needed
      },
    };
    //console.log("loadUserProfile profileData:", profileData);

    //Update state and local storage
    //setUserProfile(profileData);

    setUserProfile(prevProfile => ({
      ...prevProfile,
      ...profileData,
      contactDetails: {
        ...prevProfile.contactDetails,
        ...profileData.contactDetails,
      },
      userSettings: {
        ...prevProfile.userSettings,
        ...profileData.userSettings,
      },
    }));

    //console.log("loadUserProfile userProfile after updating with profileData:", userProfile);

    localStorage.setItem('userProfile', JSON.stringify(profileData));
    localStorage.setItem('contactDetails', JSON.stringify(profileData.contactDetails));
    localStorage.setItem('userSettings', JSON.stringify(profileData.userSettings));
    sessionStorage.setItem('frontendPermissions', JSON.stringify(permissions)); //Originally profileData.permissions
    /* console.log("------ loadUserProfile profileData.permissions:", permissions); */

    setIsLoadingUserPermissions(false)

    //Check local storage
    /*const storedUserProfile = JSON.parse(localStorage.getItem('userProfile'));
    const storedContactDetails = JSON.parse(localStorage.getItem('contactDetails'));
    const storedUserSettings = JSON.parse(localStorage.getItem('userSettings'));
    
    if (storedUserProfile && storedContactDetails && storedUserSettings) {
      console.log("Found profile data in local storage, updating state");
      setUserProfile(prevProfile => ({
        ...prevProfile,
        ...storedUserProfile,
        contactDetails: storedContactDetails,
        userSettings: storedUserSettings,
        }));
        //return;
        } else {
          console.log("loadUserProfile userProfile, ContactDetails or userSettings couldn't been saved")
      }*/

  } catch (error) {
    console.error('Error fetching profile data:', error);
  } finally {
    console.log("loadUserProfile done")
    setIsLoadingUserPermissions(false)

  }
};

export default loadUserProfile;
