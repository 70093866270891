import React, { useState } from "react";
import { Button } from "components/ui/button";
import { ButtonGroup } from "@mui/material";
import { useStateContext } from "contexts/ContextProvider";

const SetupPrivacy = ({ userProfile, updateUserProfile }) => {
  const [settings, setSettings] = useState({
    cookiePreferences: userProfile.userSettings.cookiePreferences || "todos",
  });

  const contactOptions = [
    { label: "Todos", value: "everyone", description: "Puedes ser contactado por cualquier usuario." },
    { label: "Gestores", value: "gestors", description: "Puedes ser contactado solo por usuarios que tengan rol de gestor." },
    { label: "Adminstradores", value: "admins", description: "Puedes ser contactado solo por usuarios que tengan rol de administrador." },
    { label: "Nadie", value: "none", description: "No puedes ser contactado por ningún usuario." },
  ];
  const privacyOptions = [
    { label: "Todos", value: "todos", description: "Al pretar el botón se acceptan todos los cookies. Puedes cambiarlo en cualquier momento." },
    { label: "Esenciales", value: "essential", description: "Solo los cookies esenciales se almacenan en tu navegador. Puedes cambiarlo en cualquier momento." },
    { label: "Ningunos", value: "none", description: "No se almacena ningún cookie en tu navegador. Puedes cambiarlo en cualquier momento." },
    // { label: "Nadie", value: "none", description: "No puedes ser contactado por ningún usuario." },
  ];
  const { setUserProfile } = useStateContext(); // State for user profile

  const handleOptionChange = (value) => {
    setSettings({ ...settings, cookiePreferences: value });
    // Update user profile state
    setUserProfile((prevProfile) => ({
      ...prevProfile,
      userSettings: {
        ...prevProfile.userSettings,
        cookiePreferences: value,
      },
    }));
  };
  // const handleOptionChange = (value) => {
  //   setSettings({ ...settings, whoCanContact: value });
  //   // Update user profile state
  //   updateUserProfile({
  //     ...userProfile,
  //     userSettings: {
  //       ...userProfile.userSettings,
  //       whoCanContact: value,
  //     },
  //   });
  // };

  return (
    <div className="max-w-md md:max-w-2xl mx-auto w-full flex flex-col gap-y-4 md:gap-y-8">
      <div className="md:text-center">
        <h2 className="font-medium leading-7 dark:text-white">Política de Privacidad</h2>
        <span className="text-sm dark:text-gray-200">
          Que tipos de cookies se pueden almacenar en tu navegador?
          {/* Quien me puede contactar por la comunidad? */}
        </span>
      </div>
      <div className="flex flex-col gap-2 md:gap-4">
        <ButtonGroup className="flex justify-center">
          {privacyOptions.map((option, index) => (
            <Button
              key={option.value}
              className={`${
                index === 0 ? "rounded-r-none border border-r" : index === privacyOptions.length - 1 ? "rounded-l-none border border-l-0 " : "rounded-none border border-l-0 border-r"
              } ${settings.cookiePreferences === option.value ? "dark:text-black" : "dark:text-white"}  `}
              variant={
                settings.cookiePreferences === option.value ? "default" : "outline"
              }
              onClick={() => handleOptionChange(option.value)}
            >
              {option.label}
            </Button>
          ))}
        </ButtonGroup>
        <span className="text-center dark:text-white">
          {privacyOptions.find((option) => option.value === settings.cookiePreferences)?.description}
        </span>
      </div>
    </div>
  );
};

export default SetupPrivacy;

