import React, { useState } from "react";
import TutorialPopup from "components/navigation/TutorialPopup";

const Tutorial = ({ showTutorial = false, setShowTutorial}) => {
  const [currentStep, setCurrentStep] = useState(1); // Initial step
  const tutorialTips = [

    {
      stepId: 1,
      id: "page-title",
      title: "Nombre de la página",
      text: "Este es el nombre de la página, para que siempre te puedas identificar.",
      trianglePosition: "top-left",
    },
    {
      stepId: 2,
      id: "notifications",
      title: "Notificaciones",
      text: "Aquí puedes ver tus notificaciones. Puedes marcarlas como leidas o eliminarlas.",
      trianglePosition: "top-right",
    },
    {
      stepId: 3,
      id: "quicksettings",
      title: "Ajustes rapidos",
      text: "Aquí tienes tus ajustes rapidos, como el tema y la barra lateral.",
      trianglePosition: "top-right",
    },
    {
      stepId: 4,
      id: "modify-dashboard",
      title: "Modificar la página de inicio",
      text: "Aquí puedes modificar la página de inicio. Por ejemplo, puedes agregar contenido.",
      trianglePosition: "top-right",
    },
    {
      stepId: 5,
      id: "last-update",
      title: "Ultíma fecha de actualización",
      text: "Aquí puedes ver la fecha de actualización de los datos de la página.",
      trianglePosition: "top-left",
    },
    {
      stepId: 6,
      id: "sidebar-links",
      title: "Barra lateral",
      text: "Esta es la Barra lateral. Puedes navegar entre las páginas aquí.",
      trianglePosition: "left-middle",
    },
    {
      stepId: 7,
      id: "sidebar-help",
      title: "Ayuda",
      text: "Aquí puedes ver la ayuda de la página.",
      trianglePosition: "left-bottom",
    }
    // Add more tips as needed
  ];
  const handleNextStep = () => {
    setCurrentStep((prevStep) => {
      const nextStep = (prevStep % tutorialTips.length) + 1;
      setCurrentStep(nextStep);
      const tutorialLength = tutorialTips.length;
      if (nextStep === tutorialLength + 1) {
        setShowTutorial(false);
      }
      return nextStep;
    });
  };
  
  const handlePreviousStep = () => {
    setCurrentStep((prevStep) => {
      if (prevStep === 1) {
        return tutorialTips.length;
      } else {
        return prevStep - 1;
      }
    });
  };

  return (
    <>
    {showTutorial && tutorialTips.map((tip) => (
      <TutorialPopup
      key={tip.id}
      title={tip.title}
      text={tip.text}
      elementId={tip.id}
      currentStep={currentStep}
      stepId={parseInt(tip.stepId)}
      maxSteps={tutorialTips.length}
      onNextStep={handleNextStep}
      onPreviousStep={handlePreviousStep}
      trianglePosition={tip.trianglePosition}
      />
    ))}
    </>
  )

}

export default Tutorial;