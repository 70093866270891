import React, { useState, useEffect, useMemo } from "react";
import { useStateContext } from "contexts/ContextProvider";
import ResponsiveLineChart from "components/widgets/widgetTypes/charts/LineChart";
// import ToggleWidgetData from "components/widgets/widgetWrapper/ToggleWidgetData";
import { ReactComponent as WaterPressureIcon } from "icons/indicators/water-pressure.svg";
import { ReactComponent as WaterLevelIcon } from "icons/indicators/water-level.svg";
import { ReactComponent as WaterTemperatureIcon } from "icons/indicators/water-temperature.svg";
import DataSelectorSelect from "components/widgets/DataSelectors/DataSelectorSelect";
import {
  columnsWithLabels,
  parametersWithLabels,
  timeFrames,
} from "data/translationArrays";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "components/ui/select";
import { handlePrev, handleNext, handleSetToNow } from "components/widgets/widgetTypes/charts/chartFunctions";
import generateTimeframeBounds from "components/widgets/widgetTypes/charts/generateTimeframeBounds";
import { Button } from "components/ui/button";
const ToggleWidgetData = ({ valueType, onSave, type }) => {
  const categories = [
    { label: "flow", visualLabel: "Caudal", Icon: WaterLevelIcon },
    { label: "pressure", visualLabel: "Presión", Icon: WaterPressureIcon },
    { label: "temperature", visualLabel: "Temperatura", Icon: WaterTemperatureIcon },
  ];

  const initialOptionIndex = categories.findIndex(
    (category) => category.label === (valueType[0] || "flow")
  );

  const [currentOptionIndex, setCurrentOptionIndex] =
    useState(initialOptionIndex);
  const { visualLabel, Icon } = categories[currentOptionIndex];

  const toggleOption = () => {
    const newIndex = (currentOptionIndex + 1) % categories.length;
    setCurrentOptionIndex(newIndex);
    const newOption = categories[newIndex].label;
    onSave({ valueType: [newOption] });
  };

  return (
    <Button
    variant="outline"
      className="opacity-100 h-6  transition-all  group/2 flex @sm:space-x-2 items-center hover:!opacity-100 border-gray-200 dark:text-gray-500 p-1 text-gray-200  hover:text-gray-800 dark:hover:text-gray-100 rounded-full  "
      onClick={toggleOption}>
      <span className="@sm:inline-flex capitalize @sm:pl-0.5 hidden z-10 w-auto text-xs leading-none text-current">
        {visualLabel}
      </span>
      <Icon className=" group-hover:opacity-100 group-hover/2:opacity-100 size-3.5 text-current" />
    </Button>
  );
};

const AnalyticsLineChart = ({
  onRowSelectionChange,
  selectedRowIds,
  toggleDataSelector,
  data,
}) => {

  const initialOptions = {
    city: "Valencia",
    location: "Marjals i Extremals",
    selectedTimeframe: "lastHour", // "últimas 24 horas", "últimos 7 días", "últimos 30 días"
    valueType: ["temperature"],
    sensors: [],
    categories: {
      pressure: {
        minValue: 0,
        maxValue: 1,
        dangerValue: 0.6,
        criticalValue: 0.8,
      },
      temperature: {
        minValue: 10,
        maxValue: 30,
        dangerValue: 20,
        criticalValue: 25,
      },
      flow: {
        minValue: 0,
        maxValue: 1,
        dangerValue: 0.6,
        criticalValue: 0.8,
      },
    },
    showLimits: false, //TODO True
  };

  const [selectedTimeframe, setSelectedTimeframe] = useState(timeFrames[0].value); // Default to "last10Minutes"
  const [lastSelectedDate, setLastSelectedDate] = useState(new Date());
  const [lineChartOptions, setLineChartOptions] = useState({...initialOptions,
    // selectedTimeframe: timeFrames[0].value,
  });
  const [sensors, setSensors] = useState([]);

  useEffect(() => {
    if (selectedRowIds.length > 0) {
      const newSensors = selectedRowIds
        .map((rowId) => {
          const device = data.find((entry) => entry.id === rowId);
          if (!device) return null;
          return device.deviceId;
        })
        .filter(Boolean);

      setSensors(newSensors);
      setLineChartOptions((prevOptions) => ({
        ...prevOptions,
        sensors: newSensors,
      }));
    } else {
      setSensors([]);
      setLineChartOptions((prevOptions) => ({
        ...prevOptions,
        sensors: [],
      }));
    }
  }, [selectedRowIds]);

  const updateOptions = (newOptions) => {
    setLineChartOptions((prevOptions) => ({
      ...prevOptions,
      ...newOptions,
    }));
    //console.log("updateOptions - newOptions:", newOptions);
    //console.log("updateOptions - lineChartOptions:", lineChartOptions);
  };

  // const updateTimeframeOptions = (newOptions) => {
  //   setLineChartOptions((prevOptions) => ({
  //     ...prevOptions,
  //     ...newOptions,
  //   }));
  //   console.log("updateOptions - newOptions:", newOptions);
  //   console.log("updateOptions - lineChartOptions:", lineChartOptions);
  // };

  
  const handleTimeframeChange = (value) => {
    const isCustomTimeframe = value === "Rango de tiempo personalizado";
    setLineChartOptions((prev) => ({
      ...prev,
      selectedTimeframe: value,
      customTimeframeEnabled: isCustomTimeframe,
    }));
    const now = new Date();
    setSelectedTimeframe(value);
    const { start, end } = generateTimeframeBounds(selectedTimeframe, now || lastSelectedDate);
    if (start && end) {
      
      updateOptions({ startDate: start, endDate: end });
      //console.log("handleTimeframeChange - start:", start, "end:", end);
    }
    
  };
  
  const handleViewLastAvailableData = () => {
    setSelectedTimeframe('latest');
    const { start, end } = generateTimeframeBounds(null, null);
    if (start && end) {
      updateOptions({ startDate: start, endDate: end });
      //console.log("handleViewLastAvailableData - start:", start, "end:", end);
    }
  };
  
  // const handleSetToNow = () => {
  //   const now = new Date();
  //   // setSelectedTimeframe(value);
  //   //console.log("handleSetToNow - selectedTimeframe:", selectedTimeframe);
  //   // console.log("handleSetToNow - value:", value);

  //   const { start, end } = generateTimeframeBounds(selectedTimeframe, now || lastSelectedDate);
  //   if (start && end) {

  //     updateOptions({ startDate: start, endDate: end });
  //     //console.log("handleSetToNow - start:", start, "end:", end);
  //   }

  // };
  // const handlePrev = () => {
  //   const timeframe = timeFrames.find(tf => tf.value === selectedTimeframe);
  //   const { start, end } = generateTimeframeBounds(selectedTimeframe, lastSelectedDate);
    

  //   if (start && end) {
  //     const newStartDate = new Date(start.getTime() - (end - start));
  //     const newEndDate = new Date(start.getTime());
  //     setLastSelectedDate(newStartDate);
    
  //     updateOptions({ startDate: newStartDate, endDate: newEndDate });
  //   }
  // };
  
  // const handleNext = () => {
  //   const now = new Date();
  //     const { start, end } = generateTimeframeBounds(selectedTimeframe, lastSelectedDate.getTime() > now.getTime()  ? now : lastSelectedDate);

  //   if (start && end) {
  //     const newStartDate = new Date(end.getTime());
  //     const newEndDate = new Date(end.getTime() + (end - start));
  //     setLastSelectedDate(newEndDate);  
  //     updateOptions({ startDate: newStartDate, endDate: newEndDate });
  //   }
  // };

  const availabletimeFrames = timeFrames;


  useEffect(() => {
    const now = new Date();
    const { start, end } = generateTimeframeBounds(selectedTimeframe, lastSelectedDate || now);
    if (start && end) {
      updateOptions({ startDate: start, endDate: end });
    }
  }, [selectedTimeframe]);



  return (
    <>
      {data && data.length > 0 && sensors.length > 0 ? (
        <>
          <div className="absolute right-3 top-3 flex gap-x-2">
            <Select
              value={lineChartOptions.selectedTimeframe}
              onValueChange={handleTimeframeChange}>
              <SelectTrigger className="h-6 rounded-full pr-1 text-xs capitalize">
                <SelectValue placeholder={`Selecciona una rango de tiempo`}>
                  {lineChartOptions.selectedTimeframe &&
                    availabletimeFrames.find(
                      (param) =>
                        param.value === lineChartOptions.selectedTimeframe
                    ).label}
                  {/* {typeof lineChartOptions.selectedTimeframe === 'string' ? (availabletimeFrames && availabletimeFrames.find(param => param.value === lineChartOptions.selectedTimeframe))?.label : lineChartOptions.selectedTimeframe?.label} */}
                </SelectValue>
              </SelectTrigger>
              <SelectContent>
                {availabletimeFrames.map((option) => {
                  const value = option.value || option;
                  const display = option.label || option;
                  return (
                    <SelectItem
                      className="capitalize"
                      key={value}
                      value={value}>
                      {display}
                    </SelectItem>
                  );
                })}
              </SelectContent>
            </Select>
            <ToggleWidgetData
              type="analyticsLineChart"
              valueType={lineChartOptions.valueType}
              onSave={updateOptions}
            />
          </div>
          <ResponsiveLineChart
            isAnalytics={true}
            data={data}
            options={lineChartOptions}
            onRowSelectionChange={onRowSelectionChange}
            selectedRowIds={selectedRowIds}
            toggleDataSelector={toggleDataSelector}
            selectedTimeframe={selectedTimeframe}
            lastSelectedDate={lastSelectedDate}
            updateOptions={updateOptions}
            setLastSelectedDate={setLastSelectedDate}
            // onSetToNow={() => handleSetToNow(selectedTimeframe, lastSelectedDate, updateOptions, setLastSelectedDate)}
            // onPrev={() => handlePrev(selectedTimeframe, lastSelectedDate, updateOptions, setLastSelectedDate)}
            // onNext={() => handleNext(selectedTimeframe, lastSelectedDate, updateOptions, setLastSelectedDate)}
            onViewLastAvailableData={handleViewLastAvailableData}
          />
        </>
      ) : (
        <div className="flex h-full items-center justify-center">
          <span>
            Selecciona uno o más sensores para ver los datos comparativos
          </span>
        </div>
      )}
    </>
  );
};

export default AnalyticsLineChart;
