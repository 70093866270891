// Adjusted hasPermission function to be asynchronous and wait for permissionsString
export const hasPermission = async (permission, isLoadingUserPermissions, setIsLoadingUserPermissions) => {
  // Wait until permissionsString exists in sessionStorage
  let permissionsString = sessionStorage.getItem('frontendPermissions');
  while (!permissionsString) {
    if (isLoadingUserPermissions) {
      // console.log('Waiting for permissions to load...');
      await new Promise(resolve => setTimeout(resolve, 1000)); // Wait for 1 second before checking again
      permissionsString = sessionStorage.getItem('frontendPermissions');
    } else {
      setIsLoadingUserPermissions(true);
      // Optionally, wait a bit before setting isLoadingUserPermissions back to false or making another action
      await new Promise(resolve => setTimeout(resolve, 1000));
      permissionsString = sessionStorage.getItem('frontendPermissions');
    }
  }

  try {
    // Permissions string now exists, parse it
    const permissions = JSON.parse(permissionsString);

    // Ensure permissions is an array
    if (!Array.isArray(permissions)) {
      // console.log('Permissions format is incorrect');
      return false;
    }

    // Check if the permission exists
    return permissions.includes(permission);
  } catch (error) {
    // console.log('Error retrieving permissions:', error);
    return false;
  }
};