import React, { useState, useMemo, useEffect } from "react";
import "./App.css";
import "/node_modules/react-grid-layout/css/styles.css";
import "/node_modules/react-resizable/css/styles.css";
import { BrowserRouter } from "react-router-dom";
import { useStateContext } from "contexts/ContextProvider";
import { ScrollArea } from "components/ui/scroll-area";
import "./animations.css";
//import IframePortal from './components/widgets/MapGis/IframePortal';
import Sidebar from "components/navigation/SideBar";

// import { Dashboard } from "./pages/Dashboard";
import {
  Dashboard,
  Login,
  ForgotPassword,
  ForgotPasswordReset,
  Register,
  ProfileSetup,
  // TODO: Remove these pages
  // Notifications,
  // Profile,
  // Events,
  // Reports,
  // Maps,
  // Chat,
  // Settings,
  // Community,
} from "./pages";
import SetupProfile from "components/authentication/SetupProfile";
import NavBar from "components/navigation/NavBar";
import DarkmodeButton from "./components/ui/DarkmodeButton"; // Import the DarkmodeButton component
// import { PageTitleProvider } from "contexts/PageTitleContext";
import { VerifyEmailMsg, VerifyEmail } from "./components";
import { useAuth } from "contexts/AuthContext";
import ProtectedRoute from "components/authentication/ProtectedRoute"; //Wraps each route allowing access if authenticated
import Tutorial from "components/navigation/Tutorial";
import MobileWateringManager from "components/widgets/queue/MobileWateringManager";
import { useBrand } from "contexts/BrandContext";

const AppContent = ({ isMapRoute }) => {
  const [isSidebarOpen, setIsMobileSidebarOpen] = useState(false);
  const { userProfile, activeComponent, setActiveComponent, activeLinkName } = useStateContext();
  const { isAuthenticated, isLoading } = useAuth();
  const { brand } = useBrand();

  const [resToken, setResToken] = useState(null);
  const [verToken, setVerToken] = useState(null);

  useEffect(() => {
    //const token = new URLSearchParams(location.search).get("token");
    const queryParams = new URLSearchParams(window.location.search);
    const resetToken = queryParams.get('resToken');
    const verify = queryParams.get('verToken');
    //console.log("App.js resetToken verifyToken: ",resetToken,verify)

    if (resetToken) {
      setResToken(resetToken);
    }

    if (verify) {
      setVerToken(verify);
    }
  }, []);

  /*useEffect(() => {
    console.log("Navigation useEffect called");
    //Retrieve the saved path from local storage
    if (isAuthenticated) {
      const savedPath = localStorage.getItem('currentPath');
      if (savedPath && savedPath !== location.pathname) {
        navigate(savedPath, { replace: true });
      }
    }
  }, [isAuthenticated]);*/

  /*useEffect(() => {
    // Save the current path to local storage only when it changes
    if (isAuthenticated) {
      localStorage.setItem('currentPath', location.pathname); //Save in sessionStorage?
    }
  }, [location.pathname]);*/

  const toggleMobileSidebar = () => {
    setIsMobileSidebarOpen(!isSidebarOpen);
  };

  useMemo(() => {
    console.log("App.js isSidebarOpen: ", isSidebarOpen);
  }, [isSidebarOpen]);

  useMemo(() => {
    const className = 'dark';
    const element = document.documentElement;
    const metaThemeColor = document.querySelector("meta[name=theme-color]");
    // <meta name="theme-color" media="(prefers-color-scheme: light)" content="#F6F6F6" />
    // <meta name="theme-color" media="(prefers-color-scheme: dark)" content="#111111" />
    switch (userProfile.userSettings.darkMode) {
      case 'dark':
        element.classList.add(className);
        metaThemeColor.setAttribute('content', "#111111");
        break;
      case 'light':
        element.classList.remove(className);
        metaThemeColor.setAttribute('content', "#F6F6F6");
        break;
      case 'system':
        if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
          element.classList.add(className);
        } else {
          element.classList.remove(className);
        }
        break;
      default:
        break;
    }
  }, [userProfile.userSettings.darkMode]);


  useMemo(() => {
    // Apply the font size setting globally
    const fontSize = userProfile.userSettings.fontSize || '16px'; // Default to 16px if not set
    document.documentElement.style.setProperty('--user-font-size', fontSize);
  }, [userProfile.userSettings.fontSize]);




  // TODO: Set showTutorial to false, then only set it to true when the user clicks on the "Get Started" button on the first login after setup
  const [showTutorial, setShowTutorial] = useState(false);

  const temporaryIsAuthenticated = false; // TODO Remove later

  useMemo(() => {
    const expandNavButton = document.getElementById("expandNavButton");
    if (expandNavButton) {
      expandNavButton.classList.toggle(
        "-scale-x-1",
        userProfile.userSettings.sidebarPosition === "right"
      );
    }
  }, [userProfile.userSettings.sidebarPosition]);

  if (isLoading) {
    return <div>Loading...</div>; //Test. DELETE IF DOESN'T WORK. Shows loading until auth is verified
  }


  // TODO: Remove analytics, maps, reports, etc
  // const componentMappings = {
  //   dashboard: Dashboard, analytics: Analytics, maps: Maps, reports: Reports, events: Events, community: Community,
  //   devices: Devices, profile: Profile, notifications: Notifications, profileSetup: ProfileSetup, calendar: Calendar, chat: Chat, help: Help,
  //   settings: Settings, login: Login, forgotPassword: ForgotPassword, forgotPasswordReset: ForgotPasswordReset, register: Register,
  //   verifyEmail: VerifyEmail, verifyEmailMsg: VerifyEmailMsg, setupProfile: SetupProfile,
  // };
  const componentMappings = {
    // settings: Settings, 
    dashboard: Dashboard,
    profileSetup: ProfileSetup,
    login: Login, forgotPassword: ForgotPassword, forgotPasswordReset: ForgotPasswordReset, register: Register,
    verifyEmail: VerifyEmail, verifyEmailMsg: VerifyEmailMsg, setupProfile: SetupProfile,
  };

  const authComponentMappings = {
    login: Login, forgotPassword: ForgotPassword, forgotPasswordReset: ForgotPasswordReset, register: Register,
    verifyEmail: VerifyEmail, verifyEmailMsg: VerifyEmailMsg, setupProfile: SetupProfile, profileSetup: ProfileSetup,
  };

  /**
   * 
   * 
   */
  const renderComponent = () => {
    /*if (!isAuthenticated && !temporaryIsAuthenticated) {
      return <Login />;
    }*/
    // console.log("renderComponent is called with activeComponent:", activeComponent);
    // console.log("renderComponent Available mappings:", componentMappings);
    // console.log("renderComponent Active component is:", activeComponent);

    if (resToken) {
      return <ForgotPasswordReset token={resToken} />;
    }

    if (verToken) {
      return <VerifyEmail token={verToken} />;
    }

    if (!isAuthenticated && !temporaryIsAuthenticated) {
      const Component = authComponentMappings[activeComponent] || Login;
      //console.log("App.js renderComponent Component auth: ", Component);
      return <Component />;
    }
    // console.log("renderComponent ran, is Authenticated or temporaryIsAuthenticated")
    // console.log("App.js renderComponent activeComponent: ", activeComponent);

    const Component = componentMappings[activeComponent] || Dashboard;


    const isProtected = !['login', 'forgotPassword', 'forgotPasswordReset', 'register', 'verifyEmail', 'verifyEmailMsg', 'setupProfile', 'profileSetup'].includes(activeComponent);
    return isProtected ? (
      <ProtectedRoute activeComponent={activeComponent}>
        <Component />
      </ProtectedRoute>
    ) : (
      <Component />
    );
  };
  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };
  return (
    // <PageTitleProvider>



    <div className={`app bg-gray-50  ${userProfile.userSettings.sidebarPosition === "right" ? 'grid-cols-[1fr_auto]' : 'grid-cols-[auto,_1fr]'} dark:bg-gray-1000 `}>
      <Tutorial showTutorial={showTutorial} setShowTutorial={setShowTutorial} ></Tutorial>
      <title>{`${brand.brandName} ${capitalizeFirstLetter(activeComponent)} `}</title>

      {/**<BrowserRouter>**/}
      {isAuthenticated || temporaryIsAuthenticated ? (
        <>
          <Sidebar
            sidebarPosition={userProfile.userSettings.sidebarPosition}
            isSidebarOpen={isSidebarOpen}
            setIsMobileSidebarOpen={setIsMobileSidebarOpen}
            toggleMobileSidebar={toggleMobileSidebar}
            setActiveComponent={setActiveComponent}
            activeComponent={activeComponent}
          />
          <div className={`main-content max-h-screen ${userProfile.userSettings.sidebarPosition === "right" && 'row-start-1 col-start-1'} grid grid-rows-[auto,_1fr] `}>
            <NavBar toggleMobileSidebar={toggleMobileSidebar} />
            {/**setActiveComponent={setActiveComponent} activeComponent={activeComponent} */}
            <main
              className={`w-full h-[calc(100svh_-_56px)] sm:min-h-0 sm:h-[calc(100svh_-_56px)] ro w-span-2 sm:overflow-clip relative`}>
              <div className=" flex flex-col h-full custom-scrollbar">
                {showTutorial && (
                  <div className="w-full fixed -top-20 inset-0 z-30 h-f ull bg-gray-900/20 backdrop-bl ur-lg bg-gra y-100/50  dark:bg-gr ay-950/50">
                  </div>

                )}
                {/* <MobileWateringManager /> */}

                {renderComponent()}

              </div>
            </main>
          </div>
        </>
      ) : (
        <div
          className={`main-content grid grid-rows-[auto,_1fr] col-span-2`}>
          <div className="absolute top-3 right-3">
            <DarkmodeButton />
          </div>
          <main
            className={`w-full h-full  sm:min-h-0 row-span-2 relative`}>
            <ScrollArea orientation="vertical" className=" h-full w-full max-h-[calc(100%_-_0rem)] ">
              <div className="p-4 flex items-center justify-center flex-col w-full h-full min-h-svh">

                {renderComponent()}
              </div>
            </ScrollArea>

          </main>
        </div>
      )}
      {/**</BrowserRouter>**/}

      {/**<IframePortal />**/} {/* This places the MapGis permanently in the DOM, managed by routes via context */}
    </div>
    // </PageTitleProvider>
  );
};

const App = () => (
  <BrowserRouter>
    <AppContent />
  </BrowserRouter>

);

export default App;